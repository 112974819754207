import { Injectable } from '@angular/core';
import { User } from '@data/user/user.model';
import { UserQuery } from '@data/user/user.query';

/** Used this to check user info filled out in the user profile.. Maybe could be somewhere else or other implementation, will see:) */
@Injectable({
  providedIn: 'root',
})
export class UserFormService {
  userData: User | undefined;

  constructor(private userQuery: UserQuery) {
    this.userQuery.selectUser$.subscribe((user) => {
      this.userData = user;
    });
  }

  isUserInfoValid(): Boolean {
    return !!this.userData && this.isUserNameFilledOut() && this.isInstitutionAdded();
  }

  private isUserNameFilledOut(): Boolean {
    return !!this.userData?.name && !!this.userData?.lastName;
  }

  private isInstitutionAdded(): Boolean {
    return !!this.userData?.institutionId;
  }
}
