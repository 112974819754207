import { actions, createAction, props } from '@ngneat/effects';
import { TeamInvitation } from '@data/team/team.model';

export namespace TeamActions {
  export const load = createAction('[Team] Load teams');
  export const invite = createAction('[Team] Load team data by id', props<TeamInvitation>());

  export const loadTeamsForUser = (): void => {
    actions.dispatch(TeamActions.load());
  };
}
