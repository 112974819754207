import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from '@core/core.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './layout/layout.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { EffectsNgModule } from '@ngneat/effects-ng';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { UserEffects } from '@data/user/user.effects';
import { InstitutionEffects } from '@data/institution/institution.effects';
import { SessionEffects } from '@data/session/session.effects';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ParticipantEffects } from '@data/participant/participant.effects';
import { AttendantEffects } from '@data/attendant/attendant.effects';
import { MatchEffects } from '@data/match/match.effects';
import { NgChartsModule } from 'ng2-charts';
import { JobGeneralEffects } from '@data/job-general/job-general.effects';
import { CompanyEffects } from '@data/company/company.effects';
import { TeamEffects } from '@data/team/team.effects';
import Rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: '53c5998f25754234820231131d94319b',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (environment.production) {
      this.rollbar.error(err.originalError || err);
    } else {
      console.error(err);
    }
  }
}
export function rollbarFactory() {
  return new Rollbar({ ...rollbarConfig, captureIp: 'anonymize', reportLevel: 'error' });
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    EffectsNgModule.forRoot(
      [
        UserEffects,
        TeamEffects,
        InstitutionEffects,
        SessionEffects,
        ParticipantEffects,
        MatchEffects,
        AttendantEffects,
        CompanyEffects,
        JobGeneralEffects,
      ],
      {
        dispatchByDefault: false,
      },
    ),
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/private/*`,
            allowAnonymous: false,
          },
          {
            uri: `${environment.apiUrl}/public/*`,
            allowAnonymous: true,
          },
        ],
      },
    }),
    BrowserModule,
    MatDatepickerModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    NgChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    LayoutModule,
    CoreModule,

    AppRoutingModule,

    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
