export class ArrayUtil {
  public static toMap(arr: any[], keyFn: (item: any) => any): Map<any, any> {
    const map = new Map();
    arr.forEach((it) => map.set(keyFn(it), it));
    return map;
  }
}

const booleanToNumber = (b: boolean): number => {
  return b ? 1 : 0;
};

export function compare(
  a?: number | string | boolean,
  b?: number | string | boolean,
  isAsc?: boolean,
): number {
  if (!a) {
    return isAsc ? -1 : 1;
  }
  if (!b) {
    return isAsc ? 1 : -1;
  }

  if (typeof a === 'boolean') a = booleanToNumber(a);
  if (typeof b === 'boolean') b = booleanToNumber(b);

  if (typeof a === 'string') a = a.toLowerCase();
  if (typeof b === 'string') b = b.toLowerCase();

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, undefined, { numeric: true }) * (isAsc ? 1 : -1);
  }

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function dropUndefined(obj: any): any {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.filter((item) => item !== undefined);
  }
  Object.keys(obj).map((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}

export function dropFields<T>(array: T[] | T, fields: (keyof T)[]): any {
  function drop(item: any) {
    const newItem = { ...item };
    fields.forEach((field) => delete newItem[field]);
    return newItem;
  }

  if (Array.isArray(array)) {
    return array.map((item) => {
      return drop(item);
    });
  }

  return drop(array);
}

export function keepFields<T>(array: T[] | T, fields: (keyof T)[]): any {
  function keep(item: any) {
    const newItem: Partial<T> = {};
    fields.forEach((field) => (newItem[field] = item[field]));
    return newItem;
  }

  if (Array.isArray(array)) {
    return array.map((item) => {
      return keep(item);
    });
  }

  return keep(array);
}
