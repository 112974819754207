import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime, Observable, of, startWith, switchMap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { JobGeneralQuery } from '@data/job-general/job-general.query';
import { JobGeneral } from '@data/job-general/job-general.model';
import { tap } from 'rxjs/operators';

//TODO: generalise to use also for Profile Institutions..

@Component({
  selector: 'recrewt-chips-input',
  templateUrl: './chips-input.component.html',
})
export class ChipsInputComponent implements OnInit {
  @ViewChild('chipsInput') chipsInput!: ElementRef<HTMLInputElement>;

  @Input() parentFormGroup!: UntypedFormGroup;

  @Input() formCtrlName = '';

  @Input() error = 'ChipsInputComponentLABEL NOT SET ERROR MESSAGE NOT SET';

  @Input() label: string = 'ChipsInputComponentLABEL NOT SET';

  @Input() require: boolean = false;

  @Input() value: JobGeneral[] = [];

  selectedChips: Set<JobGeneral> = new Set<JobGeneral>();

  chipOptions$: Observable<JobGeneral[]> = of([]);

  public parentFormCtrl!: AbstractControl;

  public autoCompleteControl = new UntypedFormControl();

  public showMinLengthRequirement = true;

  public nothingFoundTip = false;

  public addOnBlur = true;

  public chipsForm!: UntypedFormGroup;

  private AUTOCOMPLETE_DEBOUNCE_TIME = 1000;

  constructor(private generalJobsQuery: JobGeneralQuery) {}

  @Input() displayValue: (obj: JobGeneral) => string = (obj) => obj?.name?.de ?? '';

  @Input() trackBy: (obj: any) => string = (obj) => obj.id;

  ngOnInit() {
    this.parentFormCtrl = this.parentFormGroup.get(this.formCtrlName)!;

    //TODO: validation for chips input not working.
    this.autoCompleteControl.setValidators([Validators.required]);
    this.chipsForm = new UntypedFormGroup({ chipCtrl: this.autoCompleteControl });

    this.autoCompleteControl.updateValueAndValidity();

    if (!!this.parentFormCtrl.value) {
      this.importValuesFromIDs(this.parentFormCtrl.value);
    }

    this.chipOptions$ = this.autoCompleteControl.valueChanges.pipe(
      debounceTime(this.AUTOCOMPLETE_DEBOUNCE_TIME),
      startWith(''),
      switchMap(this.optionsFilterMap),
    );
  }

  /** To use once we pass JobGenerel as values */
  public importValues(values: JobGeneral[]) {
    values.forEach((value1) => {
      this.selectedChips.add(value1);
    });
  }

  setFormValue(): void {
    if (!!this.selectedChips.size) {
      this.parentFormCtrl.setValue([...this.selectedChips]);
    } else {
      this.parentFormCtrl.setValue(null);
    }
  }

  /** Callback function for chip remove Button. */
  onRemoveChip(chip: JobGeneral) {
    this.selectedChips.delete(chip);
    return;
  }

  /** Callback for Chip input end event. */
  onChipInputEnd(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();
    this.autoCompleteControl.setValue(null);
    this.setFormValue();
  }

  onChipSelected($event: MatAutocompleteSelectedEvent) {
    this.selectedChips.add($event.option.value);
    this.chipsInput.nativeElement.value = '';
    this.autoCompleteControl.setValue(null);
    this.setFormValue();
  }

  private importValuesFromIDs(values: string[]) {
    if ('forEach' in values) {
      (values as string[]).forEach((i) => {
        const t = this.generalJobsQuery.getEntity(i);
        if (!!t) {
          this.selectedChips.add(t);
        }
      });
    }
    this.setFormValue();
  }

  private optionsFilterMap = (searchText: string) => {
    let filters: ((entity: JobGeneral, index?: number) => boolean)[] = [];
    //filter for already selected
    //TODO: look at this: https://manudss.github.io/akita-filters-plugin/ maybe a better way if we get more filters...
    filters.push((i) => !this.selectedChips.has(i));

    if (!!searchText) {
      //filter by search text
      filters.push((i) => i.name.de.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
    }

    //select with all filters
    return this.generalJobsQuery
      .selectAll({ filterBy: filters })
      .pipe(tap((value) => this.toggleNothingFoundTooltip(value.length)));
  };

  private toggleNothingFoundTooltip(foundLength: number) {
    this.nothingFoundTip = foundLength <= 0;
  }
}
