import { actions, createAction, props } from '@ngneat/effects';

export namespace JobGeneralActions {
  export const load = createAction('[JobGeneral] Load job general data');
  export const loadById = createAction(
    '[JobGeneral] Load job general data by id',
    props<{ id: string }>(),
  );

  export const loadJobGeneral = (): void => {
    actions.dispatch(JobGeneralActions.load());
  };
}
