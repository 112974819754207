import { Team } from '@data/team/team.model';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamActions } from '@data/team/team.actions';
import { TeamQuery } from '@data/team/team.query';

@Injectable({
  providedIn: 'root',
})
export class TeamResolver implements Resolve<Observable<Team>> {
  constructor(private teamQuery: TeamQuery) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Team> {
    TeamActions.loadTeamsForUser();
    return this.teamQuery.selectWhenTruthyActiveTeam$;
  }
}
