import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { createFromDTO, createFromFile, DocType, FileDataDTO, Realm } from '@data/file/file.model';
import { FileStore } from '@data/file/file.store';
import { IIonosService } from '@core/services/upload/interface.upload.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IonosService implements IIonosService {
  private url = `${environment.apiUrl}/public/upload`;

  constructor(private http: HttpClient, private fileStore: FileStore) {}

  public upload(
    realm: Realm,
    type: DocType,
    id: string,
    file: File,
  ): Observable<HttpEvent<string[]>> | null {
    if (!file) {
      return null;
    }
    const fileData = createFromFile(file, id, type);
    this.fileStore.upsert(fileData.fqn, fileData);
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<string[]>(`${this.url}/${realm}/${type}/${id}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  delete(realm: Realm, type: DocType, id: string, fileName: string): void {
    this.fileStore.removeFor(id, type, (f) => f.name === fileName);
    this.http.delete(`${this.url}/${realm}/${type}/${id}/${fileName}`).subscribe();
  }

  meta(realm: Realm, type: DocType, id: string): Observable<FileDataDTO[]> {
    return this.http
      .get<{ files: FileDataDTO[] }>(`${this.url}/${realm}/${type}/${id}`)
      .pipe(map((value) => value.files))
      .pipe(tap((files) => this.fileStore.add(files.map((f) => createFromDTO(f, id, type)))));
  }

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.http.get(`${this.url}/${realm}/${type}/${id}/${name}`, {
      responseType: 'blob',
    });
  }
}
