import { Component, Input } from '@angular/core';
import { SectionConfig } from '../section/section-config';
import { AuthService } from '@auth0/auth0-angular';
import { resetStores } from '@datorama/akita';

@Component({
  selector: 'recrewt-section-side-nav',
  templateUrl: './section-side-nav.component.html',
  styleUrls: ['./section-side-nav.component.scss'],
})
export class SectionSideNavComponent {
  @Input() sectionConfigs: SectionConfig[] = [];

  @Input() navtitle?: string;

  @Input() subtitle?: string;

  @Input() icon = 'business';

  logoWithName = 'assets/images/RC_Logo_white.svg';

  constructor(private auth0: AuthService) {}

  logout(): void {
    resetStores();
    this.auth0.logout();
  }
}
