import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { first, Observable, of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';

interface DialogConfig<DialogType, Response> {
  type: Type<DialogType>;
  dialogData?: any; // maybe generic type for the dialog data?:)
  config?: MatDialogConfig;
  confirmCallback?: (response?: Response) => void;
  cancelCallback?: () => void;
}

@Injectable({
  providedIn: 'root',
})
/** Generic service to open different Dialog types.
 * @see Front-End for other types.
 * @typeParam T Dialog type to open.
 * @typeParam D Observable type for returned data.
 */
export class DialogService<DialogType, Response> {
  dialogRef?: MatDialogRef<DialogType>;

  private readonly DEFAULT_WIDTH = '700px';

  constructor(private dialog: MatDialog) {}

  /** use openWithCallback, if it works well rename it with simply open:)
   */
  public open(type: Type<DialogType>, data?: any, config?: MatDialogConfig): void {
    this.dialogRef = this.dialog.open(type, {
      data,
      width: this.DEFAULT_WIDTH,
      ...config,
    });
  }

  /** Trying to beautify dialog service:
   *
   * @param type Dialog Component type
   * @param dialogData Data to inject into the opened dialog.
   * @param config optional MatDialogConfig.
   * @param confirmCallback callback function, after dialog closed successfully
   * @param cancelCallback callback function for dealing if a canceled/closed dialog without "success"
   */

  public openWithCallback({
    type,
    dialogData = null,
    config = undefined,
    confirmCallback = undefined,
    cancelCallback = undefined,
  }: DialogConfig<DialogType, Response>): void {
    this.dialogRef = this.dialog.open(type, {
      data: dialogData,
      width: this.DEFAULT_WIDTH,
      ...config,
    });

    if (!!confirmCallback) {
      this.onConfirmed().subscribe(confirmCallback);
    }

    if (!!cancelCallback) {
      this.onCanceled().subscribe(cancelCallback);
    }
  }

  /** Used to get dialog results.
   *  Caution: returns null in any event of Dialog closing (f.e. pressed outside of the dialog).
   *  @returns Observable<D> or Observable of null.
   */
  public afterClosed(): Observable<Response> {
    return this.dialogRef?.afterClosed().pipe(first()) ?? of(null);
  }

  public onConfirmed(): Observable<Response> {
    return this.afterClosed().pipe(filter((x) => !!x));
  }

  public onCanceled(): Observable<Response> {
    return this.afterClosed().pipe(filter((x) => !x));
  }
}

/** Interface for Dialog components that can be used with Dialog Service:) */
export interface IDialog {
  form?: UntypedFormGroup;
}
