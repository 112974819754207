<div [formGroup]="parentFormGroup!">
  <ng-container *ngFor="let dataset of config; index as i">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon>{{ dataset.icon }}</mat-icon>
      <p class="no-pad m-b-1 m-t-2">{{ dataset.label | translate }}</p>
    </div>
    <recrewt-chip-editor
      [formControlName]="dataset.groupName"
      [predefined]="dataset.data"
    ></recrewt-chip-editor>
  </ng-container>
</div>
