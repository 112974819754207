import { actions, createAction, props } from '@ngneat/effects';
import {
  Attendant,
  ICreateExternalAttendantInput,
  ILoadAttendantsRequest,
  IUpdateAttendantChanges,
  IUpdateExternalAttendantChanges,
} from '@data/attendant/attendant.model';
import { MatchingSession } from '@data/session/session.model';
import {
  IBatchCreateWithForeignIdProps,
  ICreateWithForeignIdProps,
  IDeleteWithForeignIdProps,
  IUpdateWithForeignIdProps,
} from '@data/entity-crud.service';

export namespace AttendantActions {
  const NAMESPACE = 'Attendant'; // added this to easier remember to change it in case of code copy/paste // TODO find a better dynamic name solution..

  export const TYPES = {
    load: '[' + NAMESPACE + '] Load attendants for the session id',
    update: '[' + NAMESPACE + '] Update attendant for a given session id',
    updatePublic: '[' + NAMESPACE + '] Update external attendant public for a given session id',
    updateExternal: '[' + NAMESPACE + '] Update external attendant for a given session id',
    createExternal: '[' + NAMESPACE + '] Create external attendant for a given session id',
    batchCreateExternal:
      '[' + NAMESPACE + '] Create multiple external attendant for a given session id',
    deleteExternal: '[' + NAMESPACE + '] Delete external attendant for a given session id',
    deleteManyExternal: '[' + NAMESPACE + '] Delete many external attendant for a given session id',
    exportExternal: '[' + NAMESPACE + '] Export external attendant to company suite',
  } as const;

  export const load = createAction(TYPES.load, props<ILoadAttendantsRequest>());

  export const update = createAction(
    TYPES.update,
    props<IUpdateWithForeignIdProps<IUpdateAttendantChanges>>(),
  );

  export const updatePublic = createAction(
    TYPES.updatePublic,
    props<IUpdateWithForeignIdProps<IUpdateExternalAttendantChanges>>(),
  );

  export const updateExternal = createAction(
    TYPES.updateExternal,
    props<IUpdateWithForeignIdProps<IUpdateExternalAttendantChanges>>(),
  );

  export const createExternal = createAction(
    TYPES.createExternal,
    props<ICreateWithForeignIdProps<ICreateExternalAttendantInput>>(),
  );

  export const deleteExternal = createAction(
    TYPES.deleteExternal,
    props<IDeleteWithForeignIdProps>(),
  );

  export const deleteManyExternal = createAction(
    TYPES.deleteManyExternal,
    props<{ attendants: Attendant[] }>(),
  );

  export const importExternal = createAction(
    TYPES.batchCreateExternal,
    props<IBatchCreateWithForeignIdProps<ICreateExternalAttendantInput>>(),
  );

  export const exportExternal = createAction(TYPES.exportExternal, props<{ aid: string }>());

  export const loadAttendants = (id: MatchingSession['id']): void => {
    actions.dispatch(load({ id: id }));
  };

  export const updateAttendant = (
    sid: MatchingSession['id'],
    aid: Attendant['id'],
    changes: IUpdateAttendantChanges,
  ): void => {
    actions.dispatch(update({ input: changes, foreignId: sid, targetId: aid }));
  };
  export const updateExternalAttendant = (
    sid: MatchingSession['id'],
    aid: Attendant['id'],
    changes: IUpdateExternalAttendantChanges,
  ): void => {
    actions.dispatch(updateExternal({ input: changes, foreignId: sid, targetId: aid }));
  };

  export const updateAttendantPublic = (
    sid: MatchingSession['id'],
    aid: Attendant['id'],
    changes: IUpdateExternalAttendantChanges,
  ): void => {
    actions.dispatch(updatePublic({ input: changes, foreignId: sid, targetId: aid }));
  };

  export const createExternalAttendant = (
    request: ICreateExternalAttendantInput,
    sid: MatchingSession['id'],
    usePublic: boolean,
  ): void => {
    actions.dispatch(createExternal({ input: request, foreignId: sid, usePublic }));
  };

  export const importExternalAttendant = (
    request: ICreateExternalAttendantInput[],
    sid: MatchingSession['id'],
  ): void => {
    actions.dispatch(importExternal({ input: request, foreignId: sid }));
  };

  export const deleteExternalAttendant = (
    sid: MatchingSession['id'],
    aid: Attendant['id'],
  ): void => {
    actions.dispatch(deleteExternal({ foreignId: sid, targetId: aid }));
  };

  export const deleteManyExternalAttendant = (attendants: Attendant[]): void => {
    actions.dispatch(deleteManyExternal({ attendants }));
  };

  export const exportExternalAttendant = (aid: Attendant['id']): void => {
    actions.dispatch(exportExternal({ aid }));
  };
}
