<div class="height-match-parent" fxLayout="row">
  <div class="p-l-5 no-overflow no-select" fxFlex="200px">
    <div class="m-t-5 m-b-2">
      <h3 class="no-pad">{{ navtitle ?? '' | translate }}</h3>
    </div>
    <div class="nav-bar">
      <recrewt-section
        (sectionClicked)="stepper.selectedIndex = i"
        *ngFor="let conf of sectionConfigs; index as i"
        [active]="stepper.selectedIndex === i"
        [description]="conf.description"
        [iconId]="conf.iconId"
        [title]="conf.name"
      ></recrewt-section>
    </div>
    <br /><br />
    <button mat-button (click)="logout()">
      <span>{{ 'TOOLBAR.USER.logout' | translate }}</span>
    </button>
  </div>
  <div class="auto-overflow" fxFlex="grow">
    <mat-vertical-stepper #stepper class="no-stepper-header">
      <ng-container *ngFor="let conf of sectionConfigs">
        <mat-step *ngIf="conf.data.formGroup as fg; else noFormTpl" [stepControl]="fg">
          <form [formGroup]="fg" class="no-pad">
            <recrewt-section-content [sectionType]="conf.page"></recrewt-section-content>
          </form>
        </mat-step>
        <ng-template #noFormTpl>
          <mat-step>
            <recrewt-section-content [sectionType]="conf.page"></recrewt-section-content>
          </mat-step>
        </ng-template>
      </ng-container>
    </mat-vertical-stepper>
  </div>
</div>
