import { IHTTPParamsObject } from '@data/entity-crud.service';
import { Participant } from '../participant/participant.model';
import { MatchingSession } from '@data/session/session.model';
import { JobGeneral } from '@data/job-general/job-general.model';

export interface Match {
  /** Binary encoded bonus reasons */
  bonusReasons: number;

  categories: number;
  /** The characteristic scores of this match */
  characteristics: [
    {
      /** The characteristic category */
      category: string;
      /** The characteristic score */
      score: number;
    },
  ];
  /**  Shows how good the company fits to the user */
  companyScore: number;
  /** TODO: is it there? */
  creationTime: number;
  /**  Binary encoded frame values */
  frame: number;
  /** The matches job id, null if it is a profession-match */
  jobRef: string | null;
  /** Shows how good the job fits to the user */
  jobScore: number;
  /** The reference id for this job in the JobGeneral collection */
  jobTypeId: number;
  /** Binary encoded malus reasons */
  malusReasons: number;

  noGos: number;
  /** The user's corresponding match data **/
  matchData: any;
  /** The matches overall score*/
  score: number;
  /** The match status */
  status: MatchStatus;
  /**  The user id */
  user: string;
  /** The match id */
  _id: string;
  /** The name of the job this match was generated for*/
  matchDescription: string;
  /** The address of the matched job*/
  location: string;
  /** The start date of the matched job, null if matched in fair context*/
  startDate: Date | null;
  /** The name of the company, which created the matched job, null if profession-match' */
  companyName: string | null;
  /** The booth number of the company on the underlying fair, null if match was created in default mode or in classroom-session */
  boothNr: string | null;
  /** The matched job's corresponding match data **/
  jobData: JobGeneral;
}

export enum MatchStatus {
  NEW,
  MATCH,
  HIGHLIKE,
  LIKE,
  DISLIKE,
  APPLICATION,
  TRASH,
}

export interface ILoadMatchesRequest extends IHTTPParamsObject {
  saveStateRef: Participant['_id'];
  sessionId: MatchingSession['id'];
}

export interface ILoadMatchByIdRequest extends IHTTPParamsObject {
  id: Match['_id'];
}

export function createMatch(params: Partial<Match>) {
  return { ...params } as Match;
}

export const isProfession = (match: Match): boolean => {
  return !match.jobRef;
};
