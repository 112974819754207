import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { UserActions } from '@data/user/user.actions';
import { UserStore } from '@data/user/user.store';
import { UserService } from '@data/user/user.service';
import { HttpUtils } from '@shared/util/http.util';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  load = createEffect((actions) =>
    actions.pipe(
      ofType(UserActions.load),
      switchMap(({ roles }) => {
        return this.userService.get().pipe(
          map((user) => ({ ...user, rbacRoles: roles })),
          HttpUtils.catchAndPrintError(),
        );
      }), // TODO: hanndle errors
      tap((user) => {
        this.userStore.setLoading(false);
        if (!user) return;
        this.userStore.update(user);
      }),
    ),
  );

  update = createEffect((actions) =>
    actions.pipe(
      ofType(UserActions.update),
      switchMap(({ changes }) =>
        this.userService.update(changes).pipe(tap(() => this.userStore.update(changes))),
      ),
    ),
  );

  constructor(private userStore: UserStore, private userService: UserService) {}
}
