import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AllowedBoothData,
  AllowedCompanyData,
  AllowedJobData,
  IDateRange,
  Location,
  MatchingSession,
  SessionType,
} from '@data/session/session.model';
import { RecrewtValidators } from '@shared/util/forms.util';
import { UserQuery } from '@data/user/user.query';
import { enumValues } from '@shared/util/enum.util';
import { DEFAULT_DATE_RANGE } from '@core/constants/date';

export interface SessionDialogResponse {
  name: string;
  dates: IDateRange[];
  location: Location;
  mode: SessionType;
  locale: 'by' | 'bw';
  image: string | File | null;
  pin?: string;
  allowBoothData?: AllowedBoothData[];
  allowCompanyData?: AllowedCompanyData[];
  allowJobData?: AllowedJobData[];
}

@Component({
  selector: 'recrewt-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDialogComponent {
  logoUrl: string | null = typeof this.data?.image === 'string' ? this.data?.image : null;

  isAdmin = this.userQuery.isFairsAdmin();

  readonly AllowedBoothData: AllowedBoothData[] = enumValues(AllowedBoothData, 'string');

  readonly AllowedCompanyData = enumValues(AllowedCompanyData, 'string');

  readonly AllowedJobData = enumValues(AllowedJobData, 'string');

  form = this.initForm();

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SessionDialogComponent>,
    private userQuery: UserQuery,
    @Inject(MAT_DIALOG_DATA) public data: MatchingSession | null,
  ) {}

  get isFormValid(): boolean {
    if (this.form.value.mode === SessionType.FAIR) {
      return this.form.valid;
    } else {
      return this.form.controls.name.valid;
    }
  }

  initForm() {
    return this.formBuilder.group({
      name: [this.data?.name, [Validators.required]],
      dates: [this.data?.dates ?? [DEFAULT_DATE_RANGE], [Validators.required]],
      pin: [this.data?.pin],
      mode: [this.data?.mode ?? SessionType.DEFAULT],
      location: [this.data?.location, [RecrewtValidators.fromList()]],
      image: [this.data?.image as string | File | null],
      isProject: [this.data?.isProject],
      locale: [this.data?.locale ?? 'by'],
      allowBoothData: this.formBuilder.array(
        this.getInitialValues(this.AllowedBoothData, this.data?.allowBoothData ?? []),
      ),
      allowCompanyData: this.formBuilder.array(
        this.getInitialValues(this.AllowedCompanyData, this.data?.allowCompanyData ?? []),
      ),
      allowJobData: this.formBuilder.array(
        this.getInitialValues(this.AllowedJobData, this.data?.allowJobData ?? []),
      ),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.isFormValid) {
      const value = this.form.value;
      value.allowBoothData = value.allowBoothData?.filter(Boolean);
      value.allowCompanyData = value.allowCompanyData?.filter(Boolean);
      value.allowJobData = value.allowJobData?.filter(Boolean);
      this.dialogRef.close(value);
    }
  }

  selectLogo(file: File): void {
    this.form.controls.image.setValue(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      this.logoUrl = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  deleteLogo() {
    this.logoUrl = null;
    this.form.controls.image.setValue(null);
  }

  private getInitialValues<T>(all: T[], selected: T[]) {
    return all.map((it) => (selected.includes(it) ? it : null));
  }
}
