import { actions, createAction, props } from '@ngneat/effects';
import {
  ICreateSessionRequest,
  IUpdateSessionRequest,
  MatchingSession,
} from '@data/session/session.model';
import { SessionStatus } from '@core/enums/session-status';

export namespace SessionActions {
  export const load = createAction('[Session] Load sessions');
  export const loadById = createAction(
    '[Session] Load session data by id',
    props<{
      id: string;
      setActive: boolean;
    }>(),
  );
  export const update = createAction(
    '[Session] Update session data',
    props<{ id: string; changes: Partial<MatchingSession> }>(),
  );
  export const create = createAction(
    '[Session] Create session',
    props<{ sessionInput: ICreateSessionRequest }>(),
  );

  export const loadSessionById = (sid: string, setActive: boolean = false): void => {
    actions.dispatch(SessionActions.loadById({ id: sid, setActive: setActive }));
  };

  export const createSession = (sessionInput: ICreateSessionRequest): void => {
    actions.dispatch(SessionActions.create({ sessionInput: sessionInput }));
  };

  export const updateSession = (
    _id: string,
    sessionInput: Partial<IUpdateSessionRequest>,
  ): void => {
    actions.dispatch(SessionActions.update({ changes: sessionInput, id: _id }));
  };

  export const startSession = (_id: string): void => {
    actions.dispatch(update({ changes: { status: SessionStatus.ACTIVE }, id: _id }));
  };

  export const closeSession = (_id: string): void => {
    actions.dispatch(
      update({ changes: { status: SessionStatus.CLOSED, closedAt: new Date() }, id: _id }),
    );
  };
}
