<div fxLayout="column" fxLayoutAlign="start center">
  <div
    class="item mat-elevation-z3"
    fxLayoutAlign="center center"
    [matTooltipDisabled]="!desc"
    [matTooltip]="desc ?? '' | translate"
    matTooltipClass="tooltip"
  >
    <mat-icon class="gradient-icon" *ngIf="!iconSet">{{ icon }}</mat-icon>
    <mat-icon
      class="gradient-icon fa-icon"
      *ngIf="!!iconSet"
      [fontSet]="iconSet"
      [fontIcon]="icon"
    ></mat-icon>
  </div>
  <p class="mat-caption text-align-center m-t-1">{{ title | translate }}</p>
</div>
