import { StatusTagConfig } from '@shared/components/filter/components/status-tag/status-tag.component';
import { MatchStatus } from '@data/match/match.model';
import { COLOR_CONFIGS } from '@core/constants/colors';

export const MATCH_STATUS_TAGS = {
  new: {
    ...COLOR_CONFIGS.INFO,
    icon: 'visibility_off',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.not_seen',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.not_Seen',
  },
  match: {
    ...COLOR_CONFIGS.NEUTRAL,
    icon: 'visibility',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.seen',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.seen',
  },
  highlike: {
    ...COLOR_CONFIGS.OK,
    icon: 'favorite',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.3_super_like',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.super_like_desc',
  },
  like: {
    ...COLOR_CONFIGS.WARN,
    icon: 'thumb_up',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.2_like',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.like_desc',
  },
  dislike: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'thumb_down',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.4_dislike',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.dislike_desc',
  },
  application: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'badge',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.5_application',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.appl_desc',
  },
  trash: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'delete',
    text: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_TEXT.deleted',
    desc: 'SESSIONS.USER_MATCHES.TABLE.STATUS_TYPES_DESCRIPTION.deleted',
  },
};

export function getMatchStatusTag(status: MatchStatus): StatusTagConfig | undefined {
  switch (status) {
    case MatchStatus.NEW:
      return MATCH_STATUS_TAGS.new;
    case MatchStatus.MATCH:
      return MATCH_STATUS_TAGS.match;
    case MatchStatus.HIGHLIKE:
      return MATCH_STATUS_TAGS.highlike;
    case MatchStatus.LIKE:
      return MATCH_STATUS_TAGS.like;
    case MatchStatus.DISLIKE:
      return MATCH_STATUS_TAGS.dislike;
    case MatchStatus.APPLICATION:
      return MATCH_STATUS_TAGS.application;
    case MatchStatus.TRASH:
      return MATCH_STATUS_TAGS.trash;
    default:
      return undefined;
  }
}
