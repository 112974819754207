export function decodeBin(value: number, dataSource: any[]): any[] {
  if (!value) {
    return [];
  }
  const values = [];
  let index = 0;
  while (value != 0 && index < dataSource.length) {
    if (value % 2 !== 0) {
      values.push(dataSource[index]);
    }
    index++;
    value >>= 1;
  }
  return values;
}

export function encodeBinary(values: any[], dataSource: string[]): number {
  let mapping = 0;
  for (const value of values) {
    const idx = dataSource.indexOf(value);
    if (idx >= 0) {
      mapping |= 0b1 << idx;
    }
  }
  return mapping;
}

export function bitCount(n: number) {
  n = n - ((n >> 1) & 0x55555555);
  n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
  return (((n + (n >> 4)) & 0xf0f0f0f) * 0x1010101) >> 24;
}

export function toFlagMask(values: any[]) {
  return values.reduce((acc, i) => acc | i);
}
