<div
  [class.full-screen]="asOverlay"
  [class.height-match-parent]="!asOverlay"
  [class.width-match-parent]="!asOverlay"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="56px"
>
  <div style="position: relative">
    <img alt="Logo loading" class="loader" src="assets/images/RC_Logo_bunt_Icon_Only.svg" />
    <div class="blob"></div>
  </div>
  <p class="mat-body-2 mat-hint m-t-2">{{ text | translate }}</p>
</div>
