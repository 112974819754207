<mat-form-field [formGroup]="chipsForm" appearance="outline" class="width-match-parent">
  <mat-label>{{ label | translate }}</mat-label>
  <mat-error>{{ error | translate }}</mat-error>
  <mat-chip-list #chipList>
    <mat-chip (removed)="onRemoveChip(chip)" *ngFor="let chip of selectedChips"
      >{{ chip.name.de }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #chipsInput
      (matChipInputTokenEnd)="onChipInputEnd($event)"
      [formControl]="autoCompleteControl"
      [matAutocomplete]="auto"
      [matChipInputAddOnBlur]="addOnBlur"
      [matChipInputFor]="chipList"
      [placeholder]="'PROFILE.FORM.find_institution_label' | translate"
      matInput
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChipSelected($event)">
    <mat-option *ngFor="let option of chipOptions$ | async" [value]="option">
      {{ displayValue(option) }}
    </mat-option>
    <mat-option *ngIf="nothingFoundTip" disabled>{{
      'PROFILE.FORM.nothing_found' | translate
    }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
