import { AbstractForm } from '../abstract-form';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RecrewtValidators } from '@shared/util/forms.util';
import { JobInput } from '../../components/job-dialog/job-dialog.component';
import { AllowedJobData } from '@data/session/session.model';
import { decodeBin, encodeBinary } from '@core/services/match/match-analyser/mapper';
import { TOPICS } from '@core/constants/match/topics';

export class JobGeneralForm extends AbstractForm<JobInput> {
  harvest(formData: any): Partial<JobInput> {
    const topics = encodeBinary(formData.topics.predefined, TOPICS);
    return { ...formData, topics };
  }

  patchValue(job: JobInput) {
    const topics = job?.topics ? decodeBin(job.topics, TOPICS) : [];
    this.form.patchValue({ ...job, topics: { custom: [], predefined: topics } });
  }

  protected buildForm(
    job: JobInput | undefined,
    fb: UntypedFormBuilder,
    allowJobData: AllowedJobData,
  ): UntypedFormGroup {
    const topics = job?.topics ? decodeBin(job.topics, TOPICS) : [];
    return fb.group({
      id: [job?.id, [Validators.required]],
      identifiableName: [job?.identifiableName, [Validators.required]],
      url: [job?.url, RecrewtValidators.URL],
      desc: [
        job?.desc,
        [RecrewtValidators.requiredIf(!!allowJobData?.includes(AllowedJobData.DESC))],
      ],
      educationLevel: [
        job?.educationLevel,
        [RecrewtValidators.requiredIf(!!allowJobData?.includes(AllowedJobData.EDUCATION_LEVEL))],
      ],
      topics: [{ custom: [], predefined: topics }, []],
      specializations: [job?.specializations ?? []],
      internshipPossible: [job?.internshipPossible ?? true],
      vacation: [job?.vacation, [Validators.min(0)]],
    });
  }
}
