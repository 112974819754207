import { Component } from '@angular/core';

@Component({
  selector: 'recrewt-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  seperator: string = '>';
}

export function toAlias(s: string) {
  return '@' + s;
}
