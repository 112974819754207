import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { MatchActions } from '@data/match/match.actions';
import { MatchService } from '@data/match/match.service';
import { MatchStore } from '@data/match/match.store';
import { omitTypeFromRequest } from '@data/entity-crud.service';

@Injectable({ providedIn: 'root' })
export class MatchEffects {
  load = createEffect((actions) => {
    return actions.pipe(
      ofType(MatchActions.load),
      switchMap((request) => {
        this.matchStore.setLoading(true);
        return this.matchService.get(omitTypeFromRequest(request));
      }),
      tap((matches) => {
        this.matchStore.set(matches ?? []);
        this.matchStore.setLoading(false);
      }),
    );
  });

  loadById = createEffect((actions) => {
    return actions.pipe(
      ofType(MatchActions.loadById),
      switchMap((request) => {
        this.matchStore.setLoading(true);
        return this.matchService.getById(request.id);
      }),
      tap((match) => {
        if (!match) return;
        this.matchStore.add(match, { loading: false });
      }),
    );
  });

  constructor(private matchStore: MatchStore, private matchService: MatchService) {}
}
