import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { ArrayUtil } from '@shared/util/array.util';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { EmptyConfig } from '@shared/components/placeholder/empty-page/empty-page.component';
import { ContextMenuComponent } from '@shared/menus/context-menu/context-menu.component';

@Component({
  selector: 'recrewt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements AfterContentInit, OnChanges, AfterViewInit {
  @ContentChildren(TableCellDirective) tableCellDirectives?: QueryList<TableCellDirective>;

  _dataSource = new MatTableDataSource<T>();

  displayedColumns: string[] = [];

  cellTemplates: Map<string, TableCellDirective> = new Map();

  @ViewChild(MatSort) sort: MatSort | null = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  @Output() itemClick = new EventEmitter<T>();

  @Output() emptyClick = new EventEmitter();

  @Input() showHover: boolean = false;

  @Input() empty?: EmptyConfig;

  @Input() dataSource?: any;

  @Input() loading: boolean | null = false;

  @Input() contextMenu?: ContextMenuComponent<T | T[]>;

  @Input() public sortBy?: (sort: Sort, data: T[]) => T[];

  @Input() highlightedRowsId: string = '';

  protected selectedRows: Set<T> = new Set<T>();

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() filter?: (item: any) => boolean = () => true;

  get = (column: any) => this.cellTemplates.get(column)!;

  onRowClick(row: T, event: MouseEvent): void {
    if (event.altKey) {
      if (this.selectedRows.has(row)) {
        this.selectedRows.delete(row);
      } else {
        this.selectedRows.add(row);
      }
    } else {
      this.selectedRows.clear();
      this.itemClick.emit(row);
    }
  }

  onRightClick(row: T, event: MouseEvent): void {
    if (!this.contextMenu) {
      return;
    }
    event.preventDefault();
    console.log(this.selectedRows);
    this.contextMenu.target = this.selectedRows.size > 0 ? Array.from(this.selectedRows) : row;
    this.contextMenu.afterClose = () => {
      this.selectedRows.clear();
      this.cdr.detectChanges();
    };
    this.contextMenu.ngOnInit();
    this.contextMenu.menuTopLeftPosition.x = event.clientX + 'px';
    this.contextMenu.menuTopLeftPosition.y = event.clientY + 'px';
    this.contextMenu.matMenuTrigger?.openMenu();
  }

  ngAfterContentInit(): void {
    this.displayedColumns =
      this.tableCellDirectives?.filter((it) => !!it?.cell).map((it) => it.cell!) ?? [];
    this.cellTemplates = ArrayUtil.toMap(
      this.tableCellDirectives?.toArray() ?? [],
      (val) => val.cell,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onHighlightedIdChange(changes['highlightedRowsId']);
    const dataset = changes?.['dataSource']?.currentValue;
    if (!dataset) {
      return;
    }
    if (this.sort && this.sortBy) {
      this._dataSource.data = this.sortBy(this.sort, dataset.filter(this.filter));
    } else {
      this._dataSource.data = dataset.filter(this.filter);
    }
  }

  sortChanged(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      this._dataSource.data = this.dataSource;
      return;
    }

    if (this.sortBy) {
      this._dataSource.data = this.sortBy(sort, this._dataSource.data.slice());
    }
  }

  ngAfterViewInit(): void {
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
  }

  private onHighlightedIdChange(highlightedIdChange: SimpleChange) {
    if (!!highlightedIdChange) {
      if (highlightedIdChange.previousValue !== highlightedIdChange.currentValue) {
        this.highlightedRowsId = highlightedIdChange.currentValue;
      }
    }
  }
}
