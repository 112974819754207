import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { Filter } from '@data/filter/filter';

@Component({
  selector: 'recrewt-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent extends PopupComponent {
  @Output() filter = new EventEmitter<void>();

  @Input() filters: Filter<any, any>[] = [];

  override yOffset = 12;

  get activeAmount(): number {
    return this.filters.filter((it) => it?.isActive)?.length;
  }

  override open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
    if (this.isOpen) {
      this.onApply();
    } else {
      super.open(dropdownTpl, origin);
    }
  }

  onApply(): void {
    this.close();
  }

  override close(): void {
    super.close();
    this.filter.emit();
  }
}
