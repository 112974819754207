import { Component, Input, OnInit } from '@angular/core';
import { SearchPopupHandlerComponent } from '@shared/components/popup/base/search-popup-handler.component';
import {
  AbstractControl,
  ControlContainer,
  NG_VALUE_ACCESSOR,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'recrewt-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent extends SearchPopupHandlerComponent implements OnInit {
  @Input() matStyle = false;

  @Input() placeholder = '';

  @Input() parentFormGroup?: AbstractControl;

  @Input() error = '';

  @Input() require = false;

  @Input() isInvalid = false;

  selectControlName = 'select';

  selectFormGroup = new UntypedFormGroup({});

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.matStyle) {
      this.yOffset = -24;
    }
    const selection = this.displayValue(this.model);
    this.selectFormGroup.addControl(
      this.selectControlName,
      new UntypedFormControl(selection ? this.translate.instant(selection) : null),
    );
    this.selectFormGroup.controls[this.selectControlName].markAsTouched();

    setTimeout(() => {
      const displayValue = selection ?? this.displayValue(this.model);
      const value = displayValue != null ? this.translate.instant(displayValue) : '';
      this.selectFormGroup.controls[this.selectControlName].setValue(value);
    }, 0);
  }

  override select(option: any): void {
    super.select(option);
    const key = this.displayValue(option);
    if (key == null) {
      return;
    }
    this.selectFormGroup.controls[this.selectControlName].setValue(
      key ? this.translate.instant(key) : '',
    );
  }

  override close(): void {
    super.close();
    if (!this.parentFormGroup || !this.selectControlName) {
      return;
    }
    this.selectFormGroup.controls[this.selectControlName].markAsTouched();
  }
}
