import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { Actions } from '@ngneat/effects-ng';
import { UserStore } from '@data/user/user.store';
import { AuthService } from '@auth0/auth0-angular';
import { UserActions } from '@data/user/user.actions';
import load = UserActions.load;

@Injectable({
  providedIn: 'root',
})
export class RecrewtAuthService {
  constructor(private auth0: AuthService, private actions: Actions, private userStore: UserStore) {}

  loginUser(): void {
    this.auth0.idTokenClaims$.subscribe((user) => {
      const uid = user ? user['https://recrewt-multiplicator-platform/uid'] : null;
      const roles = user?.['https://recrewt-multiplicator-platform/roles'] ?? [];
      if (this.userStore.userHasChanged(uid)) {
        resetStores();
      }
      this.actions.dispatch(load({ roles }));
    });
  }
}
