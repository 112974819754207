<footer
  class="accent-bg"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutAlign.lt-sm="center center"
>
  <div
    [ngStyle.gt-xs]="{ width: '80%' }"
    class="p-b-3 p-t-4"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.lt-sm="start start"
    fxLayoutGap.lt-sm="20px"
  >
    <div fxLayout="column">
      <h4>Info</h4>
      <a routerLink="/info/impressum">Impressum</a>
      <a routerLink="/info/agb">AGBs</a>
      <a routerLink="/info/privacy">Datenschutzerklärung</a>
      <a href="https://recrewt.de" target="_blank noopener noreferrer">Website</a>
    </div>
    <div fxLayout="column">
      <h4>Adresse</h4>
      <p>
        <a href="https://link-landshut.de/start-ups/recrewt-2" target="_blank noopener noreferrer"
          >LINK Gründerzentrum<br
        /></a>
        <a href="https://goo.gl/maps/bsT57nhZ9fhFmy9i8" target="_blank noopener noreferrer">
          Kiem-Pauli-Stra&szlig;e 8<br />
        </a>
        84036 Landshut
      </p>
    </div>
    <div fxLayout="column">
      <h4>Kontakt</h4>
      <p class="no-pad">E-Mail: <a href="mailto:info@recrewt.de">info@recrewt.de</a></p>
      <p class="no-pad">Support: <a href="mailto:support@recrewt.de">support@recrewt.de</a></p>
      <p class="no-pad">Support-Tel.: 0156 78216300</p>
    </div>
  </div>
  <div class="p-2" fxLayout="row" fxLayoutAlign="space-between center" style="width: 80%">
    <div>© {{ currentYear }} RECREWT GmbH | Made with ♥ in Landshut</div>
    <div>
      <a
        href="https://www.linkedin.com/company/recrewt"
        mat-icon-button
        target="_blank noopener noreferrer"
      >
        <mat-icon fontIcon="fa-linkedin" fontSet="fab"></mat-icon>
      </a>
      <a
        href="https://www.instagram.com/recrewt_you"
        mat-icon-button
        target="_blank noopener noreferrer"
      >
        <mat-icon fontIcon="fa-instagram" fontSet="fab"></mat-icon>
      </a>
      <a
        href="https://www.facebook.com/RECREWT"
        mat-icon-button
        target="_blank noopener noreferrer"
      >
        <mat-icon fontIcon="fa-facebook" fontSet="fab"></mat-icon>
      </a>
    </div>
  </div>
</footer>
