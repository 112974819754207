<div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
  <mat-icon
    class="circle-icon"
    [color]="color"
    [class.primary-border]="color === 'primary'"
    [class.accent-border]="color === 'accent'"
  >
    {{ icon }}
  </mat-icon>
  <div>
    <p class="no-pad mat-caption">{{ title | translate | uppercase }}</p>
    <p class="mat-subheading-1 no-pad p-b-1">{{ desc | translate: _param }}</p>
  </div>
</div>
