import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CategoricalSelectorConfig } from '@core/constants/job/benefits';

@Component({
  selector: 'recrewt-categorical-selector-group',
  templateUrl: './categorical-chip-selector-group.component.html',
  styleUrls: ['./categorical-chip-selector-group.component.scss'],
})
export class CategoricalChipSelectorGroupComponent {
  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() config: CategoricalSelectorConfig[] = [];
}
