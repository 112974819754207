export const TOPICS = [
  'MAPPING.TOPICS.music',
  'MAPPING.TOPICS.art',
  'MAPPING.TOPICS.fashion',
  'MAPPING.TOPICS.tv',
  'MAPPING.TOPICS.health',
  'MAPPING.TOPICS.legal',
  'MAPPING.TOPICS.sport',
  'MAPPING.TOPICS.travel',
  'MAPPING.TOPICS.it',
  'MAPPING.TOPICS.tech',
  'MAPPING.TOPICS.science',
  'MAPPING.TOPICS.nature',
  'MAPPING.TOPICS.animal',
  'MAPPING.TOPICS.plant',
  'MAPPING.TOPICS.traffic',
  'MAPPING.TOPICS.vehicle',
  'MAPPING.TOPICS.fin',
  'MAPPING.TOPICS.design',
  'MAPPING.TOPICS.beauty',
  'MAPPING.TOPICS.food',
  'MAPPING.TOPICS.tools',
  'MAPPING.TOPICS.architecture',
  'MAPPING.TOPICS.hotel',
];
