<h1 mat-dialog-title>{{ config.title | translate }}</h1>
<div mat-dialog-content>
  <div class="info-box">
    <p style="white-space: pre-line">{{ config.message | translate }}</p>
    <p *ngIf="config.warning">
      <strong>{{ config.warning | translate }}</strong>
    </p>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="noActionClicked()" mat-button>{{ config.cancelText | translate }}</button>
  <button *ngIf="config.altConfirmText" [mat-dialog-close]="false" mat-button>
    {{ config.altConfirmText | translate }}
  </button>
  <button [color]="config.confirmColor" [mat-dialog-close]="true" mat-flat-button>
    {{ config.confirmText | translate }}
  </button>
</div>
