import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { IDateRange } from '@data/session/session.model';
import { RecrewtValidators } from '@shared/util/forms.util';
import { DEFAULT_DATE } from '@core/constants/date';
import moment from 'moment';

@Component({
  selector: 'recrewt-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateTimeRangePickerComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateTimeRangePickerComponent,
      multi: true,
    },
  ],
})
export class DateTimeRangePickerComponent extends CustomFormControlComponent implements OnInit {
  private readonly DEFAULT_DATE_RANGE_CONTROL = this.fb.group({
    start: [DEFAULT_DATE, [Validators.required, RecrewtValidators.endAfterStart()]],
    end: [DEFAULT_DATE, [Validators.required, RecrewtValidators.endAfterStart()]],
  });

  readonly formArray = this.fb.array([this.DEFAULT_DATE_RANGE_CONTROL]);

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.formArray.valueChanges.subscribe((res) => {
      this.onChange(res);
    });
  }

  writeValue(value?: IDateRange[]): void {
    this.formArray.clear();
    value?.forEach((v) => {
      this.formArray.push(
        this.fb.group({
          start: [
            moment(v.start).toISOString(true).slice(0, 16),
            [Validators.required, RecrewtValidators.endAfterStart()],
          ],
          end: [
            moment(v.end).toISOString(true).slice(0, 16),
            [Validators.required, RecrewtValidators.endAfterStart()],
          ],
        }),
      );
    });
  }

  addRange(): void {
    this.formArray.push(this.DEFAULT_DATE_RANGE_CONTROL);
  }

  removeRange(index: number): void {
    this.formArray.removeAt(index);
  }

  override validate(control: AbstractControl): ValidationErrors | null {
    return !this.formArray || this.formArray.valid
      ? null
      : { error: 'Some fields are not fullfilled' };
  }
}
