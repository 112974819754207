import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookableType, GeneralJobType, JobGeneral } from '@data/job-general/job-general.model';
import {
  Education_Bw,
  Education_By,
  EDUCATION_LEVEL,
  EDUCATION_LEVEL_BW,
} from '@core/constants/job/education';
import { map } from 'rxjs';
import { JobGeneralQuery } from '@data/job-general/job-general.query';
import { BENEFITS_SELECTOR_CONFIG } from '@core/constants/job/benefits';
import { Job } from '@data/attendant/attendant.model';
import { JobScholasticForm } from '../../form/job/job-scholastic.form';
import { JobBenefitsForm } from '../../form/job/job-benefits.form';
import { JobGeneralForm } from '../../form/job/job-general.form';
import { AllowedJobData, MatchingSession } from '@data/session/session.model';
import { TOPICS } from '@core/constants/match/topics';
import { enumValues } from '@shared/util/enum.util';

export interface JobDialogData {
  jobs: JobGeneral[];
  existingJobs: JobInput[];
  courses: JobGeneral[];
  currentJob?: JobInput;
  session: MatchingSession;
}

export interface JobInput extends Job {
  id: string;
}

@Component({
  selector: 'recrewt-job-dialog',
  templateUrl: './job-dialog.component.html',
  styleUrls: ['./job-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDialogComponent implements OnInit {
  form!: JobGeneralForm;

  scholasticDataForm!: JobScholasticForm;

  benefitsForm!: JobBenefitsForm;

  readonly EducationLevel: number[] = enumValues(
    this.data.session.locale === 'bw' ? Education_Bw : Education_By,
  );

  readonly BookableType = BookableType;

  readonly benefits = BENEFITS_SELECTOR_CONFIG;

  protected readonly AllowedJobData = AllowedJobData;

  protected readonly TOPICS = TOPICS;

  private currentJobId: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<JobDialogComponent>,
    private jobGeneralQuery: JobGeneralQuery,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: JobDialogData,
  ) {
    this.currentJobId = !!data.currentJob?.id;
  }

  get benefitsFormGroup() {
    return this.benefitsForm.form.get('benefits') as FormGroup;
  }

  get salaryFormArray() {
    return this.benefitsForm.form.get('estimatedSalaryEUR') as FormArray;
  }

  get type() {
    return this.jobGeneralById(this.form?.data.id)?.bookableType ?? null;
  }

  eduMapping = (id: number) => {
    if (this.data.session.locale === 'bw') {
      return EDUCATION_LEVEL_BW[id];
    } else {
      return EDUCATION_LEVEL[id];
    }
  };

  jobGeneralById = (id?: string) => this.data.jobs?.find((it) => it.id === id);

  courseById = (id?: string) => this.data.courses?.find((it) => it.id === id);

  displayValue = (value: JobGeneral) => {
    if (!value?.name) {
      return '';
    }

    if (value.type === GeneralJobType.SPECIALIZATION) {
      const baseJob = this.jobGeneralById(value.parentJobId);
      return `${baseJob?.name.de} - ${value.name.de}`;
    } else {
      return value.name.de;
    }
  };

  trackById = (val: any): any => val.id;

  getSpecializations = (id: string) => {
    return this.jobGeneralQuery.selectSpecializationsForJob(id);
  };

  hasSpecializations = (id: string) => {
    if (!id || id.includes('_')) {
      return false;
    }

    return this.jobGeneralQuery
      .selectSpecializationsForJob(id)
      .pipe(map((jobs) => jobs.length > 0));
  };

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('70vw');
    this.initFormGroup(this.data.currentJob);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.form.form.valid) {
      const value = { ...this.form.data, ...this.benefitsForm.data };
      if (this.type != BookableType.CLASSIC_TRAINING) {
        value.scholasticData = this.scholasticDataForm.data;
      }
      this.dialogRef.close(value);
    } else {
      this.form.form.markAllAsTouched();
    }
  }

  updateIdentifiableName(id: string | null) {
    if (!id) {
      return;
    }
    if (this.currentJobId) {
      return;
    }

    this.currentJobId = true;
    const existingJob = this.data.existingJobs.find((it) => it.id === id);
    if (existingJob) {
      this.form.patchValue(existingJob);
    }
    const job = this.jobGeneralById(id);
    const jobName = job ? this.displayValue(job) : null;
    this.form.form.get('identifiableName')?.setValue(jobName);
    if (this.type === BookableType.DUAL_STUDY) {
      this.scholasticDataForm.form
        .get('courseName')
        ?.setValue(jobName?.replace('Duales Studium - ', '').trim());
    }
    this.cdr.detectChanges();
  }

  updateCourse(id: string): void {
    const idControl = this.scholasticDataForm.form.get('courseId');
    const nameControl = this.scholasticDataForm.form.get('courseName');
    const course = this.data.courses.find((item) => item.id === id);
    if (course) {
      idControl?.setValue(id);
      nameControl?.setValue(course.name.de);
      this.cdr.detectChanges();
    }
  }

  initFormGroup(obj?: JobInput) {
    this.scholasticDataForm = new JobScholasticForm(obj?.scholasticData, this.formBuilder);
    this.benefitsForm = new JobBenefitsForm(obj, this.formBuilder);
    this.form = new JobGeneralForm(obj, this.formBuilder, this.data.session.allowJobData);
  }

  addSalary() {
    this.salaryFormArray.push(this.formBuilder.control(null));
  }

  appendHttps() {
    const url = this.form.form.get('url');
    if (url?.value && !url.value.startsWith('http://') && !url.value.startsWith('https://')) {
      url?.setValue(`https://${url.value}`);
      this.cdr.detectChanges();
    }
  }
}
