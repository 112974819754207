import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { SessionActions } from '@data/session/session.actions';
import { SessionStore } from '@data/session/session.store';
import { SessionService } from '@data/session/session.service';
import { HttpUtils } from '@shared/util/http.util';
import { NotificationService } from '@core/services/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class SessionEffects {
  load = createEffect((actions) =>
    actions.pipe(
      ofType(SessionActions.load),
      switchMap(() => this.sessionService.get().pipe(HttpUtils.catchAndPrintError())),
      tap((sessions) => {
        this.sessionStore.set(sessions ?? []);
      }),
    ),
  );

  loadById = createEffect((actions) =>
    actions.pipe(
      ofType(SessionActions.loadById),
      switchMap(({ id, setActive }) =>
        this.sessionService.getById(id).pipe(
          map((x) => {
            return { session: x, setActive: setActive };
          }),
        ),
      ),
      tap(({ session, setActive }) => {
        if (!session) {
          return;
        }
        this.sessionStore.add(session, { loading: false });
        if (setActive) {
          this.sessionStore.setActive(session.id);
        }
      }),
    ),
  );

  update = createEffect((actions) =>
    actions.pipe(
      ofType(SessionActions.update),
      switchMap(({ id, changes }) =>
        this.sessionService
          .update(id, changes)
          .pipe(tap(() => this.sessionStore.update(id, changes))),
      ),
    ),
  );

  create = createEffect((actions) =>
    actions.pipe(
      ofType(SessionActions.create),
      switchMap(({ sessionInput }) =>
        this.sessionService.create(sessionInput).pipe(
          tap((newSession) => {
            if (!newSession) return;
            this.sessionStore.add(newSession);
            this.sessionStore.setActive(newSession.id);
            this.notify.success('SESSIONS.success');
          }),
          catchError((err) => {
            if (err.statusCode === 409) {
              this.notify.error('ERRORS.SESSION.pinAlreadyExists');
            } else {
              this.notify.error('ERRORS.SESSION.create');
            }
            return [];
          }),
        ),
      ),
    ),
  );

  constructor(
    private sessionStore: SessionStore,
    private sessionService: SessionService,
    private notify: NotificationService,
  ) {}
}
