import { COLOR_CONFIGS } from '@core/constants/colors';
import { SessionStatus } from '@core/enums/session-status';

export const SESSION_STATUS_TAGS = [
  {
    ...COLOR_CONFIGS.INFO,
    icon: 'confirmation_number',
    text: 'SESSIONS.TABLE.STATUS.new',
  },
  {
    ...COLOR_CONFIGS.OK,
    icon: 'confirmation_number',
    text: 'SESSIONS.TABLE.STATUS.active',
  },
  {
    ...COLOR_CONFIGS.ERROR,
    icon: 'close',
    text: 'SESSIONS.TABLE.STATUS.closed',
  },
];

export function getSessionStatusTag(status: SessionStatus) {
  return SESSION_STATUS_TAGS[status] ?? undefined;
}
