import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttendantWithCompany } from '@data/attendant/attendant.model';
import { AttendantService } from '@data/attendant/attendant.service';
import { AttendantActions } from '@data/attendant/attendant.actions';

export type ExportDialogData = {
  attendant: AttendantWithCompany;
};

export enum ExportStage {
  Initial,
  Exporting,
  Exported,
  Error,
}

@Component({
  selector: 'recrewt-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
  validForExport = false;

  alreadyExported = false;

  stage = ExportStage.Initial;

  protected readonly ExportStage = ExportStage;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    private attendantService: AttendantService,
    @Inject(MAT_DIALOG_DATA) public data: ExportDialogData,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const email = this.data.attendant.company?.companyData.contact?.registrationEmail;
    const location = this.data.attendant.company?.companyData.location;
    const name = this.data.attendant.company?.companyData.name;
    this.validForExport = !!email && !!location && !!name;
    this.alreadyExported = !!this.data.attendant.company?.exportedTo;
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }

  export(): void {
    this.stage = ExportStage.Exporting;

    this.attendantService.exportToCompanySuite(this.data.attendant.attendant.id).subscribe({
      next: () => {
        AttendantActions.loadAttendants(this.data.attendant.attendant.session);
        this.stage = ExportStage.Exported;
      },
      error: () => (this.stage = ExportStage.Error),
    });
  }
}
