import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoaderComponent } from '@shared/components/loader/loader.component';

@Directive({
  selector: '[recrewtLoader]',
})
export class LoaderDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer2: Renderer2,
  ) {}

  @Input() set recrewtLoader(loading: boolean | null) {
    if (!!loading) {
      this.viewContainer.clear();
      const ref = this.viewContainer.createComponent(LoaderComponent);
      this.renderer2.addClass(ref.location.nativeElement, 'width-match-parent');
    } else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
