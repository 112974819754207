<div [ngSwitch]="connected$ | async">
  <mat-icon
    *ngSwitchCase="true"
    [matTooltip]="'SOCKET_IO.CONNECTION_INDICATOR.connected' | translate"
    matTooltipClass="tooltip"
  >
    {{ icons.connected }}</mat-icon
  >
  <mat-icon
    *ngSwitchCase="false"
    [matTooltip]="indicatorText | translate"
    matTooltipClass="tooltip"
  >
    {{ icons.disconnected }}</mat-icon
  >
  <div *ngSwitchDefault>E</div>
</div>
