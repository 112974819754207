<div
  (click)="onSectionClicked()"
  [class.on-hover]="!active"
  [class.active]="active"
  class="section m-b-1"
>
  <div>
    <p [class.primary]="active" [class.text-weight-bold]="active" class="no-pad">
      {{ title | translate }}
    </p>
  </div>
</div>
