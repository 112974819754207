<div fxFlex="grow">
  <recrewt-image-selection-dialog-button
    #imgInput
    (imageSelected)="onImageSelected($event)"
    (delete)="onDelete()"
    [firstChange]="!imageUrl || imageUrl === DEFAULT_URL"
    style="display: none"
  ></recrewt-image-selection-dialog-button>
  <div [class.inline]="appearance !== 'banner'" class="img-container">
    <div>
      <img
        *ngIf="imageUrl !== DEFAULT_URL || appearance !== 'banner'; else bannerPlaceholder"
        [class.banner]="appearance === 'banner'"
        [class.circle]="appearance === 'circle'"
        [height]="size"
        [src]="imageUrl! | safe: 'resourceUrl'"
        [width]="size"
        alt="Image upload"
        class="img-input b-rad-1"
      />
    </div>
    <div
      (click)="imgInput.click()"
      [class.banner]="appearance === 'banner'"
      [class.circle]="appearance === 'circle'"
      [ngStyle]="{ height: size + 'px', width: appearance !== 'banner' ? size + 'px' : '100%' }"
      class="after b-rad-1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ icon }}</mat-icon>
      <p class="mat-caption no-pad">{{ hint | translate }}</p>
    </div>
  </div>
</div>

<ng-template #bannerPlaceholder>
  <div
    [ngStyle]="{ height: size + 'px' }"
    class="banner-placeholder b-rad-1"
    fxLayoutAlign="center center"
  >
    <img class="b-rad-1" [height]="size / 2" [src]="DEFAULT_URL" alt="" />
  </div>
</ng-template>
