import { AbstractForm } from '../abstract-form';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BENEFITS, BENEFITS_SELECTOR_CONFIG } from '@core/constants/job/benefits';
import { Job } from '@data/attendant/attendant.model';

export class JobBenefitsForm extends AbstractForm<Job> {
  harvest(formData: any): Partial<Job> {
    const benefitsData = this.splitPredefinedAndCustomBenefits(formData.benefits);
    const salary = (formData?.estimatedSalaryEUR as number[])?.filter((it) => it !== null);
    const data: Partial<Job> = {
      estimatedSalaryEUR: salary ?? [],
      salaryUnspecified: !salary.length,
      matchData: { general: { benefits: benefitsData?.general } },
    };

    if (!!benefitsData?.custom) {
      data.matchData!.additionalData = { benefits: benefitsData.custom };
    }
    return data;
  }

  protected buildForm(job: Job, fb: UntypedFormBuilder): UntypedFormGroup {
    const benefits = job?.matchData?.general?.benefits;
    const benefitsCustom = job?.matchData?.additionalData?.benefits;
    const benefitsForm = this.initBenefitsFormGroup(fb, benefits, benefitsCustom);
    const salaryForm = this.generateSalaryForm(job?.estimatedSalaryEUR ?? [], fb);

    return fb.group({
      estimatedSalaryEUR: salaryForm,
      benefits: benefitsForm,
    });
  }

  private generateSalaryForm(salaryPerYear: number[], fb: UntypedFormBuilder): FormArray {
    const salaryForm = fb.array([]);
    salaryPerYear.forEach((salary) => {
      salaryForm.push(fb.control(salary, Validators.min(0)));
    });
    return salaryForm;
  }

  private initBenefitsFormGroup(
    fb: UntypedFormBuilder,
    predefined?: { [p: string]: number },
    custom?: { [p: string]: string[] },
  ): UntypedFormGroup {
    const benefits = fb.group({});
    BENEFITS_SELECTOR_CONFIG.forEach((category) => {
      let benefitsCustom: string[] = [];
      let benefitsPredefined: string[] = [];
      if (!!predefined) {
        const benefitsByCategory = predefined[category.groupName];
        benefitsPredefined = this.mapBinary(benefitsByCategory, BENEFITS[category.groupName]) ?? [];
      }
      if (!!custom) {
        benefitsCustom = custom[category.groupName]?.slice() ?? [];
      }
      benefits.addControl(
        category.groupName,
        fb.control({ predefined: benefitsPredefined, custom: benefitsCustom }),
      );
    });
    return benefits;
  }

  private splitPredefinedAndCustomBenefits(benefits: {
    [key: string]: { predefined: string[]; custom: string[] };
  }): {
    general: { [p: string]: number };
    custom?: { [p: string]: string[] };
  } {
    const general: { [p: string]: number } = {};
    const custom: { [p: string]: string[] } = {};
    for (const [cat, values] of Object.entries(benefits)) {
      general[cat] = this.mapArray(values.predefined ?? [], BENEFITS[cat]);
      if (!!values.custom.length) {
        custom[cat] = values.custom;
      }
    }
    if (Object.keys(custom).length) {
      return { general, custom };
    } else {
      return { general };
    }
  }

  private mapArray(values: string[], dataSource: string[]): number {
    let mapping = 0;
    for (const value of values) {
      const idx = dataSource.indexOf(value);
      if (idx >= 0) {
        mapping |= 0b1 << idx;
      }
    }
    return mapping;
  }

  private mapBinary(value: number, dataSource: string[]): string[] {
    if (!value) {
      return [];
    }
    const values = [];
    let index = 0;
    while (value > 0 && index < dataSource.length) {
      if (value % 2 !== 0) {
        values.push(dataSource[index]);
      }
      index++;
      value >>= 1;
    }
    return values;
  }
}
