import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';

@Component({
  template: '',
})
export abstract class CustomFormControlComponent implements ControlValueAccessor, Validator {
  touched = false;

  @Input() disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged(obj: any): void {
    this.markAsTouched();
    this.onChange(obj);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  abstract writeValue(obj: any): void;

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
