import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-boolean-sign',
  templateUrl: './boolean-sign.component.html',
  styleUrls: ['./boolean-sign.component.scss'],
})
export class BooleanSignComponent {
  @Input() condition: boolean = false;
}
