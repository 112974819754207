export const EDUCATION_LEVEL = [
  '',
  'MAPPING.EDUCATION.MS',
  'MAPPING.EDUCATION.Q_MS',
  'MAPPING.EDUCATION.MR',
  'MAPPING.EDUCATION.FA',
  'MAPPING.EDUCATION.A',
  '',
  'MAPPING.EDUCATION.SUPPORT',
];

export const EDUCATION_LEVEL_BW = [
  '',
  '',
  'Hauptschulabschluss',
  'Mittlere Reife/Realschulabschluss',
  'MAPPING.EDUCATION.FA',
  'MAPPING.EDUCATION.A',
];

export enum Education_By {
  MS = 1,
  Q_MS,
  MR,
  FA,
  A,
  SUPPORT = 7,
}

export enum Education_Bw {
  Q_MS = 2,
  MR,
  FA,
  A,
}
