import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DocType, FileData } from './file.model';

export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const STORAGE_FULL = 100 * 1024 * 1024;

export interface FileState extends EntityState<FileData> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'file', resettable: true, idKey: 'fqn' })
export class FileStore extends EntityStore<FileState, FileData> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  removeFor(forId: string, docType: DocType, query: (entity: Readonly<FileData>) => boolean): void {
    this.remove((f: Readonly<FileData>) => {
      return query(f) && f.forId === forId && f.docType === docType;
    });
  }
}
