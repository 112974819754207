import { ContextMenuOption } from '@shared/menus/context-menu/context-menu-option/context-menu-option';
import {
  Attendant,
  AttendantWithCompany,
  ICreateExternalAttendantInputWithCompany,
} from '@data/attendant/attendant.model';
import { Icons } from '@core/enums/icons';
import {
  InputDialogComponent,
  InputDialogData,
} from '@shared/components/dialog/input-dialog/input-dialog.component';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { Validators } from '@angular/forms';
import { AttendantActions } from '@data/attendant/attendant.actions';
import { CompanyDialogHandler } from '../../../../lib/company-elements/services/company-dialog-handler';
import { CompanyActions } from '@data/company/company.actions';
import { NotificationService } from '@core/services/notification/notification.service';
import { MatchingSession } from '@data/session/session.model';
import { ExportDialogComponent } from '@shared/components/dialog/export-dialog/export-dialog.component';

export module AttendantMenuOptions {
  export class DeleteBoothOption extends ContextMenuOption<
    AttendantWithCompany | AttendantWithCompany[]
  > {
    constructor(
      target: AttendantWithCompany | AttendantWithCompany[],
      private afterClose?: () => void,
    ) {
      super(
        Icons.close,
        'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.ACTIONS.delete_booth',
        target,
      );
    }

    onClick(): any {
      const targets = Array.isArray(this.target) ? this.target : [this.target];
      const attendants = targets.map((t) => t?.attendant).filter(Boolean) as Attendant[];
      AttendantActions.deleteManyExternalAttendant(attendants);

      this.afterClose?.apply(this);
    }
  }

  export class RenameBoothOption extends ContextMenuOption<AttendantWithCompany> {
    private newSessionDialogData: InputDialogData = {
      formErrorMessage: 'Ungültige Standnummer',
      label: 'Standnummer eingeben',
      message: 'Sie können die Standnummer für diesen Aussteller ändern.',
      title: 'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.ACTIONS.edit_booth',
      validator: Validators.required,
    };

    constructor(private dialog: DialogService<any, string>, target: AttendantWithCompany) {
      super(
        Icons.edit,
        'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.ACTIONS.edit_booth',
        target,
      );
    }

    onClick(): void {
      if (!!this.target) {
        this.openRenameBoothDialog();
      }
    }

    openRenameBoothDialog() {
      this.dialog.openWithCallback({
        type: InputDialogComponent,
        dialogData: this.newSessionDialogData,
        confirmCallback: this.onRenameBoothDialogClose,
      });
    }

    private onRenameBoothDialogClose = (dialogResponse: string | undefined) => {
      const attendant = this.target?.attendant;
      const isExternal = !!attendant?.isManuallyAdded;
      if (!!dialogResponse) {
        if (isExternal) {
          AttendantActions.updateExternalAttendant(attendant!.session!, attendant!.id!, {
            boothNr: dialogResponse,
            isManuallyAdded: true,
          });
        } else {
          AttendantActions.updateAttendant(attendant!.session!, attendant!.id!, {
            boothNr: dialogResponse,
            isManuallyAdded: false,
          });
        }
      }
    };
  }

  export class ShareSessionOption extends ContextMenuOption<AttendantWithCompany> {
    constructor(target: AttendantWithCompany, private notifier: NotificationService) {
      super('share', 'Bearbeitungslink kopieren', target);
    }

    onClick(): void {
      if (!!this.target) {
        const url = `https://${window.location.host}/public/join?s=${this.target.attendant.session}&a=${this.target.attendant.id}`;
        navigator.clipboard
          .writeText(url)
          .then(() => this.notifier.success('Bearbeitungslink kopiert'));
      }
    }
  }

  export class EditBoothOption extends ContextMenuOption<AttendantWithCompany> {
    constructor(
      private dialog: DialogService<any, any>,
      target: AttendantWithCompany,
      private session: MatchingSession,
    ) {
      super(
        Icons.edit,
        'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.ACTIONS.edit_booth',
        target,
      );
    }

    onClick(): void {
      if (!!this.target) {
        this.openEditBoothDialog();
      }
    }

    openEditBoothDialog() {
      new CompanyDialogHandler(this.dialog).openEditCompanyDialog(
        { attendant: this.target!, session: this.session },
        this.onEditCompaniesDialogClose,
      );
    }

    private onEditCompaniesDialogClose = (
      dialogResponse?: ICreateExternalAttendantInputWithCompany,
    ) => {
      if (!this.isValidResponse(dialogResponse)) {
        console.error('Invalid response from dialog', dialogResponse);
        return;
      }

      const { attendant, company } = dialogResponse!;
      CompanyActions.updateExternalCompany(attendant.importedCompany!, company);
      AttendantActions.updateExternalAttendant(
        this.target!!.attendant.session,
        this.target!!.attendant.id!,
        {
          ...attendant,
          isManuallyAdded: true,
        },
      );
    };

    private isValidResponse(dialogResponse: ICreateExternalAttendantInputWithCompany | undefined) {
      return dialogResponse && dialogResponse.attendant.importedCompany;
    }
  }

  export class ExportOption extends ContextMenuOption<AttendantWithCompany> {
    constructor(private dialog: DialogService<any, any>, target: AttendantWithCompany) {
      super(
        Icons.export,
        'SESSIONS.RUNNING_SESSION.TABLE.COMPANIES_TABLE.ACTIONS.export_booth',
        target,
      );
    }

    onClick(): void {
      if (!!this.target) {
        this.dialog.openWithCallback({
          type: ExportDialogComponent,
          dialogData: { attendant: this.target },
        });
      }
    }
  }

  export function getAttendantMenuOptions(
    attendants: AttendantWithCompany | AttendantWithCompany[],
    dialog: DialogService<any, any>,
    notifier: NotificationService,
    afterClose?: () => void,
  ): ContextMenuOption<AttendantWithCompany | AttendantWithCompany[]>[] {
    let r: ContextMenuOption<AttendantWithCompany | AttendantWithCompany[]>[] = [];

    let singleTarget;
    if (Array.isArray(attendants)) {
      if (attendants.length === 1) {
        singleTarget = attendants[0];
      }
    } else {
      singleTarget = attendants;
    }
    if (!!singleTarget) {
      if (singleTarget.attendant.isManuallyAdded) {
        r.push(new ExportOption(dialog, singleTarget));
        r.push(new ShareSessionOption(singleTarget, notifier));
        r.push(new DeleteBoothOption(singleTarget, afterClose));
      } else {
        r.push(new RenameBoothOption(dialog, singleTarget));
      }
    } else if (
      (attendants as AttendantWithCompany[]).every(
        (attendant) => attendant.attendant.isManuallyAdded,
      )
    ) {
      r.push(new DeleteBoothOption(attendants, afterClose));
    }

    return r;
  }
}
