import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initEffects } from '@ngneat/effects';
import { persistState } from '@datorama/akita';
import { deserialize } from '@data/filter/filter.store';

if (environment.production) {
  enableProdMode();
}

const providers: StaticProvider[] = [
  {
    provide: 'persistStorage',
    useValue: persistState({
      key: 'DEV_AKITA_STORAGE_KEY', //TODO: Change key
      include: ['message', 'filter'],
      preStoreUpdate(storeName, state) {
        if (storeName === 'filter') {
          return deserialize(state);
        }
        return state;
      },
    }),
  },
];

initEffects();

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
