import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { omit } from 'lodash';
import { dropUndefined } from '@shared/util/array.util';

type MaybeReturns<T> = T | undefined;

export interface IHTTPParamsObject {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> | undefined;
}

export abstract class EntityCrudService<I, O> {
  protected readonly URL_PRIVATE: string;

  protected readonly URL_PUBLIC: string;

  protected readonly PATH: string | undefined;

  protected constructor(
    private route: string,
    protected http: HttpClient,
    path: string | undefined = undefined,
  ) {
    this.URL_PRIVATE = `${environment.apiUrl}/private/${this.route}`;
    this.URL_PUBLIC = `${environment.apiUrl}/public/${this.route}`;
    this.PATH = path;
  }

  getById(id: string, params?: IHTTPParamsObject, usePublic = false): Observable<O | undefined> {
    const httpParams = new HttpParams({ fromObject: dropUndefined(params) });
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.get<O>(`${url}/${id}`, { params: httpParams });
  }

  getByForeignId(
    id: string,
    params?: IHTTPParamsObject,
    usePublic = false,
  ): Observable<O[] | undefined> {
    const httpParams = new HttpParams({ fromObject: dropUndefined(params) });
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.get<O[]>(`${url}/${id}/${this.PATH}`, { params: httpParams });
  }

  updateWithForeignId(
    request: IUpdateWithForeignIdProps<I>,
    usePublic = false,
  ): Observable<Object> {
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.put(
      `${url}/${request.foreignId}/${this.PATH}/${request.targetId}`,
      request.input,
    );
  }

  createWithForeignId(request: ICreateWithForeignIdProps<I>, usePublic = false): Observable<O> {
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.post<O>(`${url}/${request.foreignId}/${this.PATH}`, request.input);
  }

  deleteWithForeignId(request: IDeleteWithForeignIdProps): Observable<IDeleteResponse> {
    return this._delete(
      `${this.URL_PRIVATE}/${request.foreignId}/${this.PATH}/${request.targetId}`,
    );
  }

  delete(id: string): Observable<IDeleteResponse> {
    return this._delete(`${this.URL_PRIVATE}/${id}`);
  }

  batchCreateWithForeignId(request: IBatchCreateWithForeignIdProps<I>): Observable<O[]> {
    return this.http.post<O[]>(
      `${this.URL_PRIVATE}/${request.foreignId}/${this.PATH}/batch`,
      request.input,
    );
  }

  get(params?: IHTTPParamsObject, usePublic = false): Observable<O[] | undefined> {
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    const httpParams = new HttpParams({ fromObject: dropUndefined(params) });
    return this.http.get<O[]>(`${url}`, { params: httpParams });
  }

  update(id: string, changes: Partial<I>, usePublic = false) {
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.put(`${url}/${id}`, changes);
  }

  create(data: I, usePublic = false) {
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.post<MaybeReturns<O>>(`${url}`, data);
  }

  private _delete(URL: string): Observable<IDeleteResponse> {
    return this.http.delete<IDeleteResponse>(URL);
  }
}

export function omitTypeFromRequest<T extends IHTTPParamsObject>(request: T & { type: any }): T {
  return omit(request, 'type') as unknown as T;
}

//TODO: refactor using interfaces for props
export interface IRequestProps<I> {
  input: I;
}

export interface IRequestWithIdProps<I> extends IRequestProps<I> {
  targetId: string;
}

export interface IBatchRequestProps<I> {
  input: I[];
}

export interface ICreateWithForeignIdProps<I> extends IRequestProps<I> {
  foreignId: string;
  usePublic?: boolean;
}

export interface IBatchCreateWithForeignIdProps<I> extends IBatchRequestProps<I> {
  foreignId: string;
}

export interface IUpdateWithForeignIdProps<I> extends IRequestWithIdProps<I> {
  foreignId: string;
}

export interface IDeleteProps {
  targetId: string;
}

export interface IDeleteWithForeignIdProps extends IDeleteProps {
  foreignId: string;
}

export interface IDeleteResponse {
  status: string;
}

export const DELETE_OK = 'OK';
