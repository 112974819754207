import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { IDynamicComponent } from './dynamic.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentService {
  constructor(private cfr: ComponentFactoryResolver) {}

  loadComponent(vcr: ViewContainerRef, type: Type<any>, data: any): void {
    const componentFactory = this.cfr.resolveComponentFactory(type);
    vcr.clear();

    const componentRef = vcr.createComponent<IDynamicComponent>(componentFactory);
    componentRef.instance.data = data;
  }
}
