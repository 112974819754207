import { actions, createAction, props } from '@ngneat/effects';
import { ILoadMatchByIdRequest, ILoadMatchesRequest } from '@data/match/match.model';

export namespace MatchActions {
  const NAMESPACE = '[Match]'; // added this to easier remember to change it in case of code copy/paste // TODO find a better dynamic name solution..
  export const load = createAction(
    NAMESPACE + 'Load matches for user in session',
    props<ILoadMatchesRequest>(),
  );

  export const loadById = createAction(
    NAMESPACE + 'Load match by id',
    props<ILoadMatchByIdRequest>(),
  );

  export const loadMatches = (
    participantId: ILoadMatchesRequest['saveStateRef'],
    sessionId: ILoadMatchesRequest['sessionId'],
  ): void => {
    actions.dispatch(load({ saveStateRef: participantId, sessionId: sessionId }));
  };

  export const loadMatchById = (request: ILoadMatchByIdRequest): void => {
    actions.dispatch(loadById(request));
  };
}
