import { AfterViewInit, Directive } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';

const KEY = 'MAT_SORT';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[rememberSort]',
})
export class RememberSortDirective implements AfterViewInit {
  constructor(private el: MatSort) {}

  get matSort(): MatSortData | null {
    const sort = sessionStorage.getItem(window.location.pathname + '?' + KEY);
    if (!sort) {
      return null;
    }
    return JSON.parse(sort);
  }

  set matSort(mat: MatSortData | null) {
    sessionStorage.setItem(window.location.pathname + '?' + KEY, JSON.stringify(mat));
  }

  ngAfterViewInit(): void {
    if (this.matSort) {
      this.el.active = this.matSort.active;
      this.el.direction = this.matSort.direction;
      this.el.sortChange.emit(this.matSort as Sort);
    }
    this.el.sortChange.subscribe((sort: Sort) => {
      if (!sort) return;
      this.matSort = {
        active: sort.active,
        direction: sort.direction,
      };
    });
  }
}

interface MatSortData {
  active: string;
  direction: SortDirection;
}
