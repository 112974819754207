import { ICreateSessionRequest, MatchingSession } from '@data/session/session.model';
import { SessionActions } from '@data/session/session.actions';
import { ContextMenuOption } from '@shared/menus/context-menu/context-menu-option/context-menu-option';
import { SessionStatus } from '@core/enums/session-status';
import { NotificationService } from '@core/services/notification/notification.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { IonosFacadeService } from '@core/services/upload/ionos-facade.service';
import {
  SessionDialogComponent,
  SessionDialogResponse,
} from '../../../../modules/sessions/component/session-dialog/session-dialog.component';

export namespace MatchingSessionOptions {
  export class EditSessionOption extends ContextMenuOption<MatchingSession> {
    constructor(
      target: MatchingSession,
      private dialog: DialogService<SessionDialogComponent, SessionDialogResponse>,
      private upload: IonosFacadeService,
    ) {
      super('edit', 'SESSIONS.TABLE.ACTIONS.edit', target);
    }

    onClick(): void {
      if (!!this.target) {
        this.editSession(this.target);
      }
    }

    editSession(session: MatchingSession) {
      this.dialog.openWithCallback({
        type: SessionDialogComponent,
        dialogData: session,
        confirmCallback: (result) => {
          if (!result) return;
          const logo = result.image;
          if (!logo) {
            this.upload.removeDisplayImage('fairs', session.id, 'fair/logo', 'logo');
          } else if (typeof logo !== 'string') {
            this.upload.uploadDisplayImage('fairs', 'fair/logo', session.id, logo).subscribe(() => {
              const imageUrl = `https://api.recrewt.de:3000/api/web/files/fairs/fair/logo/${session.id}/logo`;
              SessionActions.updateSession(session.id, { image: imageUrl });
            });
          }

          const update = { ...result } as ICreateSessionRequest;
          delete update.image;
          delete update.pin;
          delete update.mode;

          SessionActions.updateSession(session.id, update);
        },
      });
    }
  }
  export class CloseMatchSessionOption extends ContextMenuOption<MatchingSession> {
    constructor(target: MatchingSession) {
      super('close', 'SESSIONS.TABLE.ACTIONS.close', target);
    }

    onClick(): void {
      if (!!this.target) {
        this.closeSession(this.target);
      }
    }

    closeSession(session: MatchingSession) {
      SessionActions.closeSession(session.id);
    }
  }
  export class StartMatchSessionOption extends ContextMenuOption<MatchingSession> {
    constructor(target: MatchingSession) {
      super('play_arrow', 'SESSIONS.TABLE.ACTIONS.start', target);
    }

    onClick(): void {
      if (!!this.target) {
        this.startSession(this.target);
      }
    }

    startSession(session: MatchingSession) {
      SessionActions.startSession(session.id);
    }
  }

  export class ShareSessionOption extends ContextMenuOption<MatchingSession> {
    constructor(target: MatchingSession, private notifier: NotificationService) {
      super('share', 'Beitrittslink kopieren', target);
    }

    onClick(): void {
      if (!!this.target) {
        const url = 'https://' + window.location.host + '/public/join?s=' + this.target.id;
        navigator.clipboard
          .writeText(url)
          .then(() => this.notifier.success('Beitrittslink kopiert'));
      }
    }
  }

  export function getSessionMenuOptions(
    session: MatchingSession,
    dialog: DialogService<any, any>,
    notifier: NotificationService,
    upload: IonosFacadeService,
  ): ContextMenuOption<MatchingSession>[] {
    let r: ContextMenuOption<MatchingSession>[] = [];
    r.push(new EditSessionOption(session, dialog, upload));
    if (session.status === SessionStatus.NEW || session.status === SessionStatus.ACTIVE) {
      r.push(new CloseMatchSessionOption(session));
    }
    if (session.status === SessionStatus.NEW) {
      r.push(new StartMatchSessionOption(session));
    }
    r.push(new ShareSessionOption(session, notifier));
    return r;
  }
}
