import auth_config from '../auth_config.json';

export const environment = {
  production: false,
  apiUrl: 'https://test.api.recrewt.de/api/mp',
  auth: {
    domain: auth_config.domain,
    clientId: auth_config.clientId,
    redirectUri: window.location.origin,
    audience: auth_config.audience,
  },
  SOCKET_ENDPOINT: 'https://test.api.recrewt.de/mp/sessions',
  mapbox: {
    apiKey:
      'sk.eyJ1IjoidmF2ZS1kZXYiLCJhIjoiY2t1YzgwdDN4MHh2ZTJ3bG1nam03NGJxbSJ9.Yc3o-RsHZSgpS_cbzRCwxA',
  },
};
