import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { ImageCropComponent } from '../image-cropper/image-crop.component';

@Component({
  selector: 'recrewt-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ImageSelectorComponent implements OnChanges {
  readonly DEFAULT_URL = 'assets/images/logo.png';

  @Input() appearance: 'square' | 'circle' | 'banner' = 'circle';

  @Input() size = 100;

  @Input() icon = 'add_a_photo';

  @Input() hint = '';

  @Input() imageUrl: string | null = this.DEFAULT_URL;

  @Output() fileSelected = new EventEmitter<File>();

  @Output() fileDeleted = new EventEmitter<void>();

  constructor(
    private imageCropper: DialogService<
      ImageCropComponent,
      { file: File; compress: boolean } | number | null
    >,
  ) {}

  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputFile = input?.files?.item(0);
    if (!inputFile) {
      return;
    }
    if (!input?.accept?.includes(inputFile.type)) {
      return;
    }

    this.openCropper(event, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const imgUrl = changes['imageUrl']?.currentValue;
    this.imageUrl = imgUrl?.length ? imgUrl : this.DEFAULT_URL;
  }

  onDelete(): void {
    this.imageUrl = this.DEFAULT_URL;
    this.fileDeleted.emit();
  }

  // eslint-disable-next-line
  private openCropper(event: any, compress: boolean) {
    this.imageCropper.open(ImageCropComponent, {
      fileChangedEvent: event,
      aspectRatio: this.appearance === 'banner' ? 2.47 : 1,
      resizeToWidth: true,
      maintainAspectRatio: true,
      roundCropper: this.appearance === 'circle',
    });
    this.imageCropper.onConfirmed().subscribe((it) => {
      if (!it) {
        return;
      } else if (typeof it === 'number') {
        this.onDelete();
      } else {
        this.fileSelected.emit(it.file);
      }
    });
  }
}
