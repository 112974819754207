import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { InstitutionActions } from '@data/institution/institution.actions';
import { InstitutionStore } from '@data/institution/institution.store';
import { InstitutionService } from '@data/institution/institution.service';

@Injectable({ providedIn: 'root' })
export class InstitutionEffects {
  load = createEffect((actions) =>
    actions.pipe(
      ofType(InstitutionActions.load),
      tap(() => this.institutionStore.setLoading(true)),
      switchMap(({ name }) => this.institutionService.get({ name })),
      tap((institutions) => {
        this.institutionStore.setLoading(false);
        if (!institutions) return;
        institutions.forEach((i) => this.institutionStore.upsert(i.id, i));
      }),
    ),
  );

  // Sets the loaded Institution as active. Used on Profile-Page.
  loadById = createEffect((actions) =>
    actions.pipe(
      ofType(InstitutionActions.loadById),
      switchMap(({ id }) => this.institutionService.getById(id)),
      tap((institution) => {
        if (!institution) return;
        this.institutionStore.add(institution, { loading: false });
        this.institutionStore.setActive(institution.id);
      }),
    ),
  );

  update = createEffect((actions) =>
    actions.pipe(
      ofType(InstitutionActions.update),
      switchMap(({ id, changes }) =>
        this.institutionService
          .update(id, changes)
          .pipe(tap(() => this.institutionStore.update(id, changes))),
      ),
    ),
  );

  // Sets the loaded Institution as active. Used on Profile-Page.
  create = createEffect((actions) =>
    actions.pipe(
      ofType(InstitutionActions.create),
      switchMap(({ institution }) =>
        this.institutionService.create(institution).pipe(
          tap((newInstitution) => {
            if (!newInstitution) return;
            this.institutionStore.add(newInstitution);
            this.institutionStore.setActive(newInstitution.id);
          }),
        ),
      ),
    ),
  );

  constructor(
    private institutionStore: InstitutionStore,
    private institutionService: InstitutionService,
  ) {}
}
