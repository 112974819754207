<h1 mat-dialog-title>Aussteller auf Unternehmensplattform übertragen</h1>
<div mat-dialog-content>
  <div class="width-match-parent">
    <div *ngIf="alreadyExported">
      <p>Der Aussteller wurde bereits auf die Unternehmensplattform übertragen.</p>
    </div>
    <div *ngIf="!validForExport && !alreadyExported">
      <p>
        Übertragung auf Unternehmensplattform nicht möglich. Folgende Felder müssen für den Export
        auf der Unternehmensplattform ausgefüllt sein:
      </p>
      <ul>
        <li>Firmenname</li>
        <li>Registrierungsemail</li>
        <li>Standort</li>
      </ul>
    </div>
    <div *ngIf="validForExport && !alreadyExported && stage === ExportStage.Initial">
      <p>Möchten Sie den Aussteller wirklich auf die Unternehmensplattform übertragen?</p>
      <p>
        Stellen Sie sicher dass die Registierungsemailadresse stimmt! Für diese wird automatisch ein
        neuer Space erzeugt!
      </p>
      <h4 class="no-pad p-v-1">
        {{ data.attendant.company?.companyData?.contact?.registrationEmail }}
      </h4>
    </div>
    <div *ngIf="stage === ExportStage.Exporting">
      <p>
        Aussteller wird auf die Unternehmensplattform exportiert. Dieser Vorgang kann bis zu einer
        Minute dauern. Bitte haben Sie Geduld...
      </p>
      <div class="p-v-3" fxLayoutAlign="center center">
        <mat-spinner diameter="64" mode="indeterminate"></mat-spinner>
      </div>
    </div>
    <div *ngIf="stage === ExportStage.Exported">
      <div fxLayoutAlign="center center">
        <img height="120" loading="lazy" src="assets/animations/success.gif" />
      </div>
      <p>Der Aussteller wurde erfolgreich auf die Unternehmensplattform exportiert.</p>

      <p>
        Die Lehrstellen wurden als inaktiv erstellt. Folgende Felder müssen auf jeden Fall vor der
        Veröffentlichung ausgefüllt werden:
      </p>
      <p>Firmenprofil:</p>
      <ul>
        <li>Berufsfelder</li>
        <li>Unternehmensgröße</li>
      </ul>
      <p>Inserate:</p>
      <ul>
        <li>Rahmenbedingungen</li>
        <li>Aufgaben</li>
        <li>Bewerbungsdetails (falls nicht über externe Karriereseite)</li>
      </ul>
    </div>
    <div *ngIf="stage === ExportStage.Error">
      <div fxLayoutAlign="center center">
        <img height="120" loading="lazy" src="assets/animations/failure.gif" />
      </div>
      <p>Der Aussteller konnte nicht auf die Unternehmensplattform exportiert.</p>
      <p>Mögliche Gründe:</p>
      <ul>
        <li>Der Aussteller wurde bereits exportiert</li>
        <li>Die zuvor genannten Pflichtfelder sind nicht vorhanden oder ungültig</li>
        <li>
          Die Messe kann nicht über die Unternehmensplattform gebucht werden oder ist falsch
          konfiguriert
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button
    (click)="noActionClicked()"
    *ngIf="stage <= ExportStage.Exporting && validForExport && !alreadyExported"
    [disabled]="stage > ExportStage.Initial"
    mat-button
  >
    {{ 'COMMON.cancel' | translate }}
  </button>
  <button
    (click)="export()"
    *ngIf="stage <= ExportStage.Exporting && validForExport && !alreadyExported"
    [disabled]="stage > ExportStage.Initial"
    color="warn"
    mat-flat-button
  >
    Auf Unternehmensplattform übertragen
  </button>
  <button
    (click)="noActionClicked()"
    *ngIf="stage > ExportStage.Exporting || !validForExport || alreadyExported"
    color="primary"
    mat-flat-button
  >
    Beenden
  </button>
</div>
