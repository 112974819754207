import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RecrewtAuthService } from '@core/services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'recrewt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private authService: RecrewtAuthService) {
    translate.setDefaultLang('de');
  }

  ngOnInit(): void {
    if (!location.href.includes('public')) {
      this.authService.loginUser();
    }
  }
}
