<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let opt of menuOptions">
      <button (click)="opt.onClick($event)" mat-menu-item>
        <mat-icon>{{ opt.icon }}</mat-icon>
        <span>{{ opt.text | translate }}</span>
      </button>
    </ng-container>
    <button *ngIf="!menuOptions?.length" [disabled]="true" mat-menu-item>
      <span>Nichts zu tun.</span>
    </button>
  </ng-template>
</mat-menu>

<button
  (click)="$event.stopPropagation()"
  *ngIf="!isRightClick"
  [disabled]="!menuOptions?.length"
  [matMenuTriggerFor]="contextMenu"
  mat-icon-button
>
  <mat-icon>more_horiz</mat-icon>
</button>

<div
  *ngIf="isRightClick"
  [matMenuTriggerFor]="contextMenu"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  style="visibility: hidden; position: fixed"
></div>
