import { Injectable } from '@angular/core';
import {
  createFromBlob,
  createFromFile,
  DocType,
  FileData,
  FileDataDTO,
  Realm,
} from '@data/file/file.model';
import { Observable, of, Subject } from 'rxjs';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { FileStore } from '@data/file/file.store';
import { FileQuery } from '@data/file/file.query';
import { map, tap } from 'rxjs/operators';
import { IonosService } from '@core/services/upload/ionos.service';
import { ImageCompressService } from '../../../lib/file-elements/services/image-compress.service';
import { IIonosService } from '@core/services/upload/interface.upload.service';

@Injectable({
  providedIn: 'root',
})
export class IonosFacadeService implements IIonosService {
  constructor(
    private fileQuery: FileQuery,
    private fileStore: FileStore,
    private imageCompress: ImageCompressService,
    private fileService: IonosService,
  ) {}

  delete(realm: Realm, type: DocType, id: string, fileName: string): void {
    this.fileService.delete(realm, type, id, fileName);
  }

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.fileService.download(realm, type, id, name);
  }

  meta(realm: Realm, type: DocType, id: string): Observable<FileDataDTO[]> {
    if (this.fileQuery.getHasCacheFor(realm, id, type)) {
      return of([]);
    }

    return this.fileService.meta(realm, type, id);
  }

  upload(
    realm: Realm,
    type: DocType,
    id: string,
    file: File,
  ): Observable<HttpEvent<string[]>> | null {
    try {
      if (file.type.startsWith('image') && !file.type.includes('svg')) {
        const subject = new Subject<any>();
        this.compressAndUpload(realm, type, id, file, subject);
        return subject.asObservable();
      } else {
        const upload = this.fileService.upload(realm, type, id, file);
        upload?.subscribe();
        return upload;
      }
    } catch (e) {
      return null;
    }
  }

  uploadDisplayImage(
    realm: Realm,
    context: DocType,
    id: string,
    file: File,
  ): Observable<FileData | null> {
    const name = 'logo';
    const img = new File([file], name, { type: file.type });
    if (!id) {
      return of(null);
    }
    return (
      this.upload(realm, context, id, img)?.pipe(
        map((evt) => {
          if (evt instanceof HttpResponse) {
            return createFromFile(img, id, context);
          } else {
            return null;
          }
        }),
      ) ?? of(null)
    );
  }

  loadDisplayImage(
    realm: Realm,
    context: 'company/logo' | 'fair/logo',
    id: string,
  ): Observable<Blob> | null {
    const name = 'logo';
    if (this.fileQuery.getHasCacheFor(realm, id, context)) {
      return of(this.fileQuery.getEntityByNameFor(realm, id, context, name)?.blob ?? new Blob());
    }
    return this.fileService.download(realm, context, id, name).pipe(
      tap((blob) => {
        const fileData = createFromBlob(blob, id, name, context);
        this.fileStore.upsert(fileData.fqn, fileData);
        this.fileStore.setLoading(false);
      }),
    );
  }

  removeDisplayImage(realm: Realm, forId: string, context: DocType, name: string): void {
    const oldImg = this.fileQuery.getFor(realm, forId, context);
    for (const img of oldImg) {
      this.fileStore.remove(img.fqn);
      this.fileService.delete(realm, context, forId, name);
    }
  }

  private compressAndUpload(
    realm: Realm,
    type: DocType,
    id: string,
    document: File,
    sub: Subject<any>,
  ) {
    return this.imageCompress.compressFile(document, ({ file }) => {
      if (!file) {
        return null;
      }
      this.fileService.upload(realm, type, id, file)?.subscribe((evt) => {
        sub.next(evt);
      });
      return undefined;
    });
  }
}
