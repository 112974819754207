<button #button (click)="click()" [disabled]="disabled" mat-flat-button>
  {{ 'COMMON.change' | translate }}
</button>
<input
  #imgInput
  (change)="this.imageSelected.emit($event)"
  accept="image/bmp, image/jpeg, image/png, image/jpg"
  style="display: none"
  type="file"
/>
