import { Injectable } from '@angular/core';
import { UserQuery } from '@data/user/user.query';
import { BehaviorSubject, Observable } from 'rxjs';
import { SocketIoWrapperService } from '@core/services/socket.io/socket-io-wrapper.service';
import { SessionStore } from '@data/session/session.store';
import { SessionUser } from '@data/session/session.model';
import { ParticipantStore } from '@data/participant/participant.store';
import { Participant } from '@data/participant/participant.model';
import { User } from '@data/user/user.model';
import { log } from '../../../testing/DevUtils/logging';

export enum SocketEvents {
  PARTICIPANT_JOINED = 'student joined',
  PARTICIPANT_LEFT = 'student left',
  MATCHES_GENERATED = 'matches generated',
  MATCH_RECEIVED = 'match rated',
  MATCH_REVOKED = 'match revoked',
  SESSION_CREATED = 'session created',
  SESSION_CLOSED = 'session closed',
  ERROR = 'error',
  CONNECTED = 'connect',
  DISCONNECTED = 'disconnect',
}

export enum DisconnectReasons {
  SERVER_DISCONNECTED = 'io server disconnect', // The server has forcefully disconnected the socket with socket.disconnect() -> NOT RECONNECTING
  CLIENT_DISCONNECTED = 'io client disconnect', // The socket was manually disconnected using socket.disconnect() -> NOT RECONNECTING
  PING_TIMEOUT = 'ping timeout', // The server did not send a PING within the pingInterval + pingTimeout range  -> AUTO RECONNECT
  TRANSPORT_CLOSE = 'transport close', // The connection was closed (example: the user has lost connection, or the network was changed from WiFi to 4G) -> AUTO RECONNECT
  TRANSPORT_ERROR = 'transport error', //The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle) -> AUTO RECONNECT
  UNKNOWN = 'UNKNOWN', // shouldnt happen....
}

export type ConnectionState = {
  connected: boolean;
  reason?: DisconnectReasons;
};

export type IJoinedParticipant = {
  userId: string;
  userName: string;
};

@Injectable({
  providedIn: 'root',
})
export class SocketIoClientService {
  private readonly connectionState$: BehaviorSubject<ConnectionState> =
    new BehaviorSubject<ConnectionState>({
      connected: false,
      reason: DisconnectReasons.UNKNOWN,
    });

  private sessionId?: string;

  constructor(
    private socket: SocketIoWrapperService,
    private userQuery: UserQuery,
    private sessionStore: SessionStore,
    private participantStore: ParticipantStore,
  ) {}

  public initConnection(sessionId: string) {
    this.sessionId = sessionId;
    if (!this.isConnected()) {
      log.call(this, 'Connecting to room ' + this.sessionId);
      this.userQuery.selectUserWhenTruthy$.subscribe(() => this.connect());
    } else {
      // should not happen. log for devs.
      log.call(this, 'Already connected');
    }
  }

  public disconnect(): void {
    this.socket?.disconnect();
  }

  public isConnected(): boolean {
    return this.socket?.connected();
  }

  getConnectionState(): Observable<ConnectionState> {
    return this.connectionState$.asObservable();
  }

  private onParticipantJoined = (joinedParticipant: IJoinedParticipant): void => {
    log.call(this, joinedParticipant, 'Socketio: Participant joined!, ');

    this.sessionStore.addUserToSession(
      this.sessionId!,
      this.joinedUserToSessionUser(joinedParticipant),
    );
    this.participantStore.addJoiningParticipant(
      this.joinedParticipantToParticipantState(joinedParticipant),
      this.sessionId!,
    );
  };

  private joinedUserToSessionUser(user: IJoinedParticipant): SessionUser {
    return { _id: user.userId, name: user.userName };
  }

  private onParticipantLeft = (participantId: any): void => {
    log.call(this, participantId, 'Socketio: Participant left!, ');
    this.sessionStore.removeUserFromSession(this.sessionId!, participantId.userId);
  };

  private connect() {
    const user: User = this.userQuery.getValue();
    if (!!user.name) {
      this.socket.connect(this.userQuery.getValue().activeTeam, this.userQuery.getValue().name!);
      this.socket?.on(SocketEvents.CONNECTED, this.onConnected);
      this.socket?.on(SocketEvents.DISCONNECTED, this.onDisconnect);
      this.socket?.on(SocketEvents.PARTICIPANT_JOINED, this.onParticipantJoined);
      this.socket?.on(SocketEvents.PARTICIPANT_LEFT, this.onParticipantLeft);
    } else {
      log.call(this, ' No username.', 'could not connect'); //TODO: Error handling.
    }
  }

  private onConnected = () => {
    log.call(this, 'connected!');
    this.createRoom(); //TODO: check if already connected to the room, and not connect/create again?, didnt find an easy way immediately, maybe BE check is enough?
    this.updateConnectionState();
  };

  /** used to update connections state subject.
   * */
  private updateConnectionState(reason?: DisconnectReasons): void {
    if (this.isConnected()) {
      // maybe it reconnected somehow?...
      this.connectionState$.next({ connected: true });
    } else {
      this.connectionState$.next({ connected: false, reason: reason ?? DisconnectReasons.UNKNOWN });
    }
  }

  private onDisconnect = (reason?: string) => {
    log.call(this, reason, ' SocketIO disconnecting, reason');
    switch (reason) {
      case DisconnectReasons.CLIENT_DISCONNECTED:
        // no auto reconnect.
        log.call(this, 'CLIENT_DISCONNECTED : ' + reason);
        this.updateConnectionState(DisconnectReasons.CLIENT_DISCONNECTED);
        break;
      case DisconnectReasons.SERVER_DISCONNECTED:
        // no auto reconnect.
        log.call(this, 'SERVER_DISCONNECTED : ' + reason);
        this.updateConnectionState(DisconnectReasons.SERVER_DISCONNECTED);
        this.socket?.reconnect();
        break;
      case DisconnectReasons.PING_TIMEOUT:
        // auto reconnecting..
        log.call(this, 'PING_TIMEOUT : ' + reason);
        this.updateConnectionState(DisconnectReasons.PING_TIMEOUT);
        break;
      case DisconnectReasons.TRANSPORT_CLOSE:
        // auto reconnecting..
        this.updateConnectionState(DisconnectReasons.TRANSPORT_CLOSE);
        log.call(this, 'TRANSPORT_CLOSE : ' + reason);
        break;

      case DisconnectReasons.TRANSPORT_ERROR:
        // auto reconnecting..
        this.updateConnectionState(DisconnectReasons.TRANSPORT_ERROR);
        log.call(this, 'TRANSPORT_ERROR : ' + reason);
        break;

      default:
        // TODO: Handle unknown reasons...
        log.call(this, 'socket io disconnect reason unknown: ' + reason);
        this.updateConnectionState();
        break;
    }
  };

  private joinedParticipantToParticipantState(joinedParticipant: IJoinedParticipant): Participant {
    return { _id: joinedParticipant.userId, name: joinedParticipant.userName, isWebUser: false };
  }

  private createRoom() {
    this.socket.emit(SocketEvents.SESSION_CREATED, {
      sessionId: this.sessionId,
    });
  }
}
