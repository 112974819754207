import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityCrudService } from '@data/entity-crud.service';
import { Company, ICreateExternalCompanyInput } from '@data/company/company.model';
import { Observable } from 'rxjs';
import { TeamQuery } from '@data/team/team.query';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityCrudService<ICreateExternalCompanyInput, Company> {
  constructor(http: HttpClient, private teamQuery: TeamQuery) {
    super('companies', http);
  }

  override create(data: ICreateExternalCompanyInput, usePublic = false) {
    // const team = this.teamQuery.getActiveId();
    // if (!team) {
    //   console.error('No active team found. Cannot create company.');
    //   return EMPTY;
    // }
    return super.create(data, usePublic);
  }

  public getByName(name: string): Observable<Company[] | undefined> {
    // const team = this.teamQuery.getActiveId();
    // if (!team) {
    //   console.error('No active team found. Cannot get company by name.');
    //   return EMPTY;
    // }
    return this.get({ name });
  }
}
