import { actions, createAction, props } from '@ngneat/effects';
import {
  ILoadParticipantByIdRequest,
  ILoadParticipantsRequest,
  Participant,
} from '@data/participant/participant.model';
import { MatchingSession } from '@data/session/session.model';

export namespace ParticipantActions {
  const NAMESPACE = '[Participant]'; // added this to easier remember to change it in case of code copy/paste // TODO find a better dynamic name solution..

  export const load = createAction(
    NAMESPACE + ' Load participants for sessionId',
    props<ILoadParticipantsRequest>(),
  );

  export const loadById = createAction(
    NAMESPACE + ' Load participant data by id',
    props<ILoadParticipantByIdRequest>(),
  );

  export const loadParticipant = (sessionId: ILoadParticipantsRequest['sessionId']): void => {
    actions.dispatch(load({ sessionId: sessionId }));
  };

  export const loadParticipantById = (
    participantId: Participant['_id'],
    sessionId: MatchingSession['id'],
    setActive: boolean = false,
  ): void => {
    actions.dispatch(
      loadById({ participantId: participantId, sessionId: sessionId, setActive: setActive }),
    );
  };
}
