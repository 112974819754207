import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Member, Role, TeamWithMembers } from '@data/team/team.model';
import { EMPTY, Observable } from 'rxjs';
import { enumValues } from '@shared/util/enum.util';
import { DialogService } from '@shared/components/dialog/dialog.service';
import {
  InputDialogComponent,
  InputDialogData,
} from '@shared/components/dialog/input-dialog/input-dialog.component';
import { Validators } from '@angular/forms';
import { TeamService } from '@data/team/team.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogConfig,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { EmptyConfig } from '@shared/components/placeholder/empty-page/empty-page.component';

@Component({
  selector: 'recrewt-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss'],
})
export class TeamDialogComponent implements OnInit {
  readonly MemberRoles: Role[] = enumValues(Role);

  readonly emptyConfig: EmptyConfig = {
    buttonIcon: 'add',
    buttonText: 'TEAMS.EMPTY.button',
    icon: 'groups',
    headline: 'TEAMS.EMPTY.headline',
    text: 'TEAMS.EMPTY.text',
  };

  team$: Observable<TeamWithMembers> = EMPTY;

  constructor(
    private dialogRef: MatDialogRef<TeamDialogComponent>,
    private inviteDialog: DialogService<any, string>,
    private teamService: TeamService,
  ) {}

  getMembers = (team?: TeamWithMembers) => (team?.members ? team?.members.slice(1) : []);

  ngOnInit(): void {
    this.team$ = this.teamService.getMembers();
    this.dialogRef.updateSize('70vw');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  inviteClicked() {
    const data: InputDialogData = {
      title: 'TEAMS.INVITE.title',
      message: 'TEAMS.INVITE.message',
      formErrorMessage: 'TEAMS.INVITE.invalidEmail',
      validator: Validators.email,
      label: 'TEAMS.INVITE.email',
    };

    this.inviteDialog.openWithCallback({
      type: InputDialogComponent,
      dialogData: data,
      confirmCallback: (email) => this.invite(email),
    });
  }

  deleteClicked(member: Member) {
    const data: ConfirmDialogConfig = {
      title: 'TEAMS.DELETE.title',
      message: 'TEAMS.DELETE.message',
      confirmText: 'TEAMS.DELETE.confirm',
      cancelText: 'COMMON.cancel',
      confirmColor: 'warn',
    };

    this.inviteDialog.openWithCallback({
      type: ConfirmDialogComponent,
      dialogData: data,
      confirmCallback: () => this.removeMember(member),
    });
  }

  submit(): void {
    this.dialogRef.close(true);
  }

  updateMember(member: Member, role: Role) {
    if (!member) {
      return;
    }

    this.teamService.updateMember(member.user, { role }).subscribe();
  }

  private invite(email?: string) {
    if (!email) {
      return;
    }

    this.teamService
      .invite({ email, role: Role.CAN_VIEW })
      .subscribe(() => (this.team$ = this.teamService.getMembers()));
  }

  private removeMember(member: Member) {
    if (!member) {
      return;
    }

    this.teamService
      .removeMember(member.user)
      .subscribe(() => (this.team$ = this.teamService.getMembers()));
  }
}
