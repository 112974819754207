import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash';

export abstract class AbstractForm<T> {
  protected readonly formGroup: UntypedFormGroup;

  constructor(data: T | undefined, fb: UntypedFormBuilder, config?: any) {
    this.formGroup = this.buildForm(data, fb, config);
  }

  get form(): UntypedFormGroup {
    return this.formGroup;
  }

  get data(): Partial<T> {
    return this.harvest(this.form.value);
  }

  setValue(field: string, value: any): void {
    const control = get(this.formGroup.controls, field);
    control.setValue(value);
  }

  abstract harvest(formData: any): Partial<T>;

  protected abstract buildForm(
    data: T | undefined,
    fb: UntypedFormBuilder,
    config?: any,
  ): UntypedFormGroup;
}
