import { actions, createAction, props } from '@ngneat/effects';
import {
  Company,
  ICreateExternalCompanyInput,
  ILoadCompaniesRequest,
  IUpdateExternalCompanyChanges,
} from '@data/company/company.model';
import { MatchingSession } from '@data/session/session.model';
import {
  IBatchCreateWithForeignIdProps,
  IDeleteProps,
  IRequestWithIdProps,
} from '@data/entity-crud.service';

export namespace CompanyActions {
  const NAMESPACE = 'Company'; // added this to easier remember to change it in case of code copy/paste

  export const TYPES = {
    load: '[' + NAMESPACE + '] Load companys for the session id',
    update: '[' + NAMESPACE + '] Update company for a given session id',
    updateExternal: '[' + NAMESPACE + '] Update external company for a given session id',
    updatePublic: '[' + NAMESPACE + '] Update external company public for a given session id',
    createExternal: '[' + NAMESPACE + '] Create external company for a given session id',
    batchCreateExternal:
      '[' + NAMESPACE + '] Create multiple external company for a given session id',
    deleteExternal: '[' + NAMESPACE + '] Delete external company for a given session id',
  } as const;

  export const load = createAction(TYPES.load, props<ILoadCompaniesRequest>());

  export const updateExternal = createAction(
    TYPES.updateExternal,
    props<IRequestWithIdProps<IUpdateExternalCompanyChanges>>(),
  );

  export const updatePublic = createAction(
    TYPES.updatePublic,
    props<IRequestWithIdProps<IUpdateExternalCompanyChanges>>(),
  );

  export const createExternal = createAction(
    TYPES.createExternal,
    props<ICreateExternalCompanyInput>(),
  );

  export const deleteExternal = createAction(TYPES.deleteExternal, props<IDeleteProps>());

  export const batchCreateExternal = createAction(
    TYPES.batchCreateExternal,
    props<IBatchCreateWithForeignIdProps<ICreateExternalCompanyInput>>(),
  );

  export const loadCompanies = (params: ILoadCompaniesRequest): void => {
    actions.dispatch(load(params));
  };

  export const updateExternalCompany = (
    id: Company['id'],
    changes: IUpdateExternalCompanyChanges,
  ): void => {
    actions.dispatch(updateExternal({ input: changes, targetId: id }));
  };

  export const updateCompanyPublic = (
    id: Company['id'],
    changes: IUpdateExternalCompanyChanges,
  ): void => {
    actions.dispatch(updatePublic({ input: changes, targetId: id }));
  };

  export const createExternalCompany = (request: ICreateExternalCompanyInput): void => {
    actions.dispatch(createExternal(request));
  };

  export const batchCreateExternalCompany = (
    request: ICreateExternalCompanyInput[],
    sid: MatchingSession['id'],
  ): void => {
    actions.dispatch(batchCreateExternal({ input: request, foreignId: sid }));
  };

  export const deleteExternalCompany = (id: Company['id']): void => {
    actions.dispatch(deleteExternal({ targetId: id }));
  };
}
