export enum DateFilterOption {
  Before = 'liegt vor',
  After = 'liegt nach',
  Equals = 'ist gleich',
  BeforeEquals = 'ist vor oder gleich',
  AfterEquals = 'ist nach oder gleich',
}

export enum NumberFilterOption {
  LessThan = 'ist kleiner als',
  LessThanEquals = 'ist kleiner oder gleich',
  MoreThan = 'ist größer als',
  MoreThanEquals = 'ist größer oder gleich',
  Equals = 'ist gleich',
}

export enum StringFilterOption {
  Contains = 'enthält',
  NotContains = 'enthält nicht',
  Equals = 'ist gleich',
  NotEquals = 'ist ungleich',
  StartsWith = 'beginnt mit',
  EndsWith = 'endet mit',
}
