import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { IDialog } from '@shared/components/dialog/dialog.service';

export interface ConfirmDialogConfig {
  title: string;
  message: string;
  confirmText: string;
  altConfirmText?: string;
  cancelText: string;
  confirmColor: ThemePalette;
  warning?: string;
  disableClose?: boolean;
}

@Component({
  selector: 'recrewt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements IDialog {
  form = undefined;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: ConfirmDialogConfig,
  ) {
    dialogRef.disableClose = config.disableClose;
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }
}
