export enum ContextMenuTypes {
  MATCHING_SESSION,
  ACTIVE_SESSION,
  USER_MATCHES,
  ATTENDANTS_TABLE,
  ATTENDANTS_CONTEXT,
  EXTERNAL_ATTENDANTS_TABLE,
}

/** Abstract class as base for Context menu options
 * @typeParam Target Object type returned by selecting the option. Will be initialised by the passed target value to ContextMenuComponent.
 * @param icon mat-icon string for icon
 * @param text String for option text
 */
export abstract class ContextMenuOption<Target> {
  protected constructor(
    public icon: string,
    public text: string,
    private _target: Target | undefined,
  ) {}

  get target(): Target | undefined {
    return this._target;
  }

  set target(target: Target | undefined) {
    this._target = target;
  }

  public abstract onClick(event: MouseEvent): any;
}
