import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Member,
  Role,
  Team,
  TeamInvitation,
  TeamWithMembers,
  TeamWithPrivileges,
} from '@data/team/team.model';
import { EntityCrudService } from '@data/entity-crud.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends EntityCrudService<Team, Team> {
  constructor(http: HttpClient) {
    super('teams', http);
  }

  override get(): Observable<TeamWithPrivileges[] | undefined> {
    return this.http.get<TeamWithPrivileges[]>(`${this.URL_PRIVATE}`);
  }

  getMembers() {
    return this.http
      .get<TeamWithMembers[]>(`${this.URL_PRIVATE}/members`)
      .pipe(map((teams) => teams?.[0]));
  }

  invite(request: TeamInvitation) {
    return this.http.post<Member[]>(`${this.URL_PRIVATE}/invite`, request);
  }

  removeMember(user: string) {
    return this.http.delete(`${this.URL_PRIVATE}/remove`, { params: { user } });
  }

  updateMember(user: string, changes: { role: Role }) {
    return this.http.put(`${this.URL_PRIVATE}`, changes, { params: { user } });
  }
}
