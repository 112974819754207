import { ICreateExternalAttendantInputWithCompany } from '@data/attendant/attendant.model';
import { JobGeneralActions } from '@data/job-general/job-general.actions';
import { DialogService } from '@shared/components/dialog/dialog.service';
import {
  CompanyDialogComponent,
  CompanyDialogData,
} from '../components/company-dialog/company-dialog.component';
import { Injectable } from '@angular/core';

const addCompaniesDialogData = {
  title: 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.title',
  message: 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.message',
};

const editCompanyDialogData = {
  message: 'SESSIONS.RUNNING_SESSION.EDIT_COMPANY_DIALOG.message',
  title: 'SESSIONS.RUNNING_SESSION.EDIT_COMPANY_DIALOG.title',
};

@Injectable({
  providedIn: 'root',
})
export class CompanyDialogHandler {
  constructor(
    private readonly companyDialog: DialogService<
      CompanyDialogComponent,
      ICreateExternalAttendantInputWithCompany
    >,
  ) {}

  public openEditCompanyDialog(
    attendant: Omit<CompanyDialogData, 'message' | 'edit' | 'title'>,
    callback: (response?: ICreateExternalAttendantInputWithCompany) => void,
  ) {
    this.companyDialog.openWithCallback({
      type: CompanyDialogComponent,
      dialogData: {
        ...editCompanyDialogData,
        ...attendant,
        edit: true,
      },
      config: { minWidth: '370px' },
      confirmCallback: (response) => (response ? callback(response) : undefined),
    });
  }

  public openAddCompaniesDialog(
    attendant: Omit<CompanyDialogData, 'message' | 'edit' | 'title'>,
    callback: (response?: ICreateExternalAttendantInputWithCompany) => void,
  ) {
    JobGeneralActions.loadJobGeneral();
    this.companyDialog.openWithCallback({
      type: CompanyDialogComponent,
      config: { minWidth: '370px' },
      dialogData: { ...addCompaniesDialogData, ...attendant },
      confirmCallback: (response) => (response ? callback(response) : undefined),
    });
  }
}
