export interface Team {
  _id: string;
  name: string;
}

export interface TeamWithPrivileges extends Team {
  privileges: Privileges;
}

export interface TeamWithMembers extends Team {
  members: Member[];
}

export interface Member extends Privileges {
  user: string;
  team: string;
  email: string;
}

export interface Privileges {
  role: Role;
  isActivated: boolean;
}

export interface TeamInvitation {
  email: string;
  role: Role;
}

export enum Role {
  CAN_EDIT,
  CAN_VIEW,
}
