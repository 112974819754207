import { createAction, props } from '@ngneat/effects';
import { Institution, NewInstitution } from '@data/institution/institution.model';

export namespace InstitutionActions {
  export const load = createAction(
    '[Institution] Load institutions by name',
    props<{ name: string }>(),
  );
  export const loadById = createAction(
    '[Institution] Load institution by id',
    props<{ id: string }>(),
  );
  export const update = createAction(
    '[Institution] Update institution',
    props<{ id: string; changes: Partial<Institution> }>(),
  );
  export const create = createAction(
    '[Institution] Create institution',
    props<{ institution: NewInstitution }>(),
  );
}
