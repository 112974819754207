import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Participant } from './participant.model';
import { ParticipantActions } from '@data/participant/participant.actions';
import { MatchingSession } from '@data/session/session.model';

export interface ParticipantState
  extends EntityState<Participant, Participant['_id']>,
    ActiveState<Participant['_id']> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Participant', resettable: true, idKey: '_id' })
export class ParticipantStore extends EntityStore<ParticipantState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor

  addJoiningParticipant(joinedParticipant: Participant, sessionId: MatchingSession['id']) {
    this.add(joinedParticipant);
    ParticipantActions.loadParticipantById(joinedParticipant._id, sessionId);
  }
}
