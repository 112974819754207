import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { JobGeneralActions } from '@data/job-general/job-general.actions';
import { JobGeneralStore } from '@data/job-general/job-general.store';
import { JobGeneralService } from '@data/job-general/job-general.service';

@Injectable({ providedIn: 'root' })
export class JobGeneralEffects {
  load = createEffect((actions) => {
    return actions.pipe(
      ofType(JobGeneralActions.load),
      tap(() => this.jobGeneralStore.setLoading(true)),
      switchMap(() => {
        //TODO: this should really be cached?! No chance of it changing ? better implementation?
        //TODO: this will also break if loadById is run before.
        if (!this.jobGeneralStore.getValue()?.ids?.length) {
          return this.jobGeneralService.get(undefined, true);
        } else {
          this.jobGeneralStore.setLoading(false);
          return [];
        }
      }),
      tap((jobs) => {
        if (!jobs) {
          this.jobGeneralStore.setLoading(false);
          return;
        }
        this.jobGeneralStore.set(jobs);
        this.jobGeneralStore.setLoading(false);
      }),
    );
  });

  loadById = createEffect((actions) =>
    actions.pipe(
      ofType(JobGeneralActions.loadById),
      switchMap(({ id }) => this.jobGeneralService.getById(id)),
      tap((job) => {
        if (!job) return;
        this.jobGeneralStore.add(job, { loading: false });
        this.jobGeneralStore.setActive(job.id);
      }),
    ),
  );

  constructor(
    private jobGeneralStore: JobGeneralStore,
    private jobGeneralService: JobGeneralService,
  ) {}
}
