<h1 mat-dialog-title>{{ 'TEAMS.EDIT.title' | translate }}</h1>
<div mat-dialog-content>
  <button
    (click)="inviteClicked()"
    *ngIf="(team$ | async | fn: getMembers).length"
    color="accent"
    mat-flat-button
  >
    <mat-icon>add</mat-icon>{{ 'TEAMS.EDIT.invite' | translate }}
  </button>
  <recrewt-table
    (emptyClick)="inviteClicked()"
    [dataSource]="team$ | async | fn: getMembers"
    [empty]="emptyConfig"
    [loading]="!(team$ | async)"
  >
    <ng-template cell="email" columnTitle="TEAMS.EDIT.COLUMNS.email" let-member>
      <span>{{ member.email }}</span>
      <span class="on-surface-medium p-l-s">{{
        member.isActivated ? '' : '(' + ('TEAMS.EDIT.COLUMNS.pending' | translate) + ')'
      }}</span>
    </ng-template>

    <ng-template cell="role" columnTitle="TEAMS.EDIT.COLUMNS.role" let-member>
      <mat-form-field appearance="legacy">
        <mat-select
          (selectionChange)="updateMember(member, $event.value)"
          [value]="member.role"
          placeholder=""
          style="min-width: 200px"
        >
          <mat-option *ngFor="let role of MemberRoles" [value]="role">
            {{ 'TEAMS.ROLES.' + role | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <ng-template cell="actions" let-member>
      <button (click)="deleteClicked(member)" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </ng-template>
  </recrewt-table>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="submit()" color="accent" mat-flat-button type="submit">
    {{ 'COMMON.confirm' | translate }}
  </button>
</div>
