/** Generates Strings array from an enum object for i18n translations.
 * Works by adding enum values to the i18n string path provided.
 * @return String Array with i18n paths.
 * @param enumObj  pass the Enum object to get translation for the values.
 * @param startOfString: the path for translations.
 *  Improvements possible? Maybe using a https://blog.ninja-squad.com/2019/12/10/angular-localize/  */
export function getTranslationsArrayForEnum(enumObj: Object, startOfString: string): string[] {
  let r: string[] = [];
  (Object.values(enumObj).filter((value) => typeof value === 'string') as string[]).forEach(
    (value) => r.push(startOfString + value),
  );

  return r;
}

export function enumKeys(Enum: any, keyType = 'number') {
  return Object.keys(Enum).filter((k) => typeof Enum[k as any] === keyType);
}

export function enumValues(Enum: any, keyType = 'number') {
  const keys = enumKeys(Enum, keyType);
  return keys.map((k) => Enum[k as any]);
}
