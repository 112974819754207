import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { debounceTime, first, map, Observable, of } from 'rxjs';
import { GeocoderService } from '@core/services/geocoder/geocoder.service';
import { Location } from '@data/session/session.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { RecrewtValidators } from '@shared/util/forms.util';

@UntilDestroy()
@Component({
  selector: 'recrewt-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LocationSelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LocationSelectorComponent,
    },
  ],
})
export class LocationSelectorComponent extends CustomFormControlComponent implements OnInit {
  @Input() predefinedOnly = false;

  @Input() predefined: Location[] = [];

  @Input() error = 'LOCATION SELECTOR ERROR MESSAGE NOT SET';

  @Input() label: string = 'LOCATION SELECTOR LABEL NOT SET';

  @Input() required = false;

  @Input() useMatStyle = true;

  searchControl = new FormControl();

  locationForm = new FormGroup({
    location: new FormControl(),
  });

  results$: Observable<Location[]> = of([]);

  constructor(private locationService: GeocoderService, private cdr: ChangeDetectorRef) {
    super();
  }

  displayValue = (value: Location) => value?.address ?? '';

  street = (value: Location) => value?.address?.split(',')[0]?.trim() ?? '';

  city = (value: Location) => value?.address?.split(',')[1]?.trim() ?? '';

  ngOnInit(): void {
    this.results$ = of(this.predefined);
    this.locationForm.markAllAsTouched();
    this.observeSearchValueChange();
  }

  setFormFieldValue(value: Location | null) {
    this.onChange(value);
    this.locationForm.controls.location.setValue(value);
    this.cdr.detectChanges();
  }

  writeValue(obj: any): void {
    this.initFromValue(obj);
  }

  override validate(control: AbstractControl): ValidationErrors | null {
    this.locationForm.controls.location.addValidators(RecrewtValidators.fromList());
    if (this.required) {
      this.locationForm.controls.location?.addValidators(Validators.required);
    }
    return super.validate(control);
  }

  private initFromValue(value: string | Location | null) {
    if (typeof value === 'string') {
      this.findLocations(value.trim().toLowerCase());
      this.results$.pipe(first()).subscribe((res) => {
        this.locationForm.controls.location.setValue(res[0] ?? null);
      });
    } else if (typeof value === 'object') {
      this.locationForm.controls.location.setValue(value);
    }
  }

  private observeSearchValueChange() {
    this.searchControl?.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((term: string | Location) => {
        if (term == null || term === '') {
          this.results$ = of(this.predefined);
          this.setFormFieldValue(null);
        }
        if (!(typeof term === 'string')) return;
        if (!(term?.trim()?.length >= 3) || this.predefinedOnly) return;

        this.findLocations(term);
        this.cdr.detectChanges();
      });
  }

  private findLocations(term: string) {
    this.results$ = this.locationService.searchAddress(term?.trim()?.toLowerCase()).pipe(
      map((value) => {
        return this.mergePredefinedAndSearchResults(value, term);
      }),
    );
  }

  private mergePredefinedAndSearchResults(results: Location[], term: string): Location[] {
    return results.concat(
      this.predefined.filter((p) => p.address?.toLowerCase().includes(term.toLowerCase())),
    );
  }
}
