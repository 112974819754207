import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { Institution } from '@data/institution/institution.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'institution', resettable: true })
export class InstitutionStore extends EntityStore<Institution> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
