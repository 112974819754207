import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Match } from '@data/match/match.model';

export interface MatchState extends EntityState<Match, Match['_id']>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Match', resettable: true, idKey: '_id' })
export class MatchStore extends EntityStore<MatchState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
