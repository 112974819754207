<form [formGroup]="form">
  <mat-form-field [formGroupName]="nestedGroupName" appearance="outline" class="width-match-parent">
    <mat-label>{{ label | translate }}</mat-label>
    <input #input [formControlName]="formCtrlName" [matAutocomplete]="auto" matInput />
    <mat-error>{{ error | translate }}</mat-error>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async; last as last" [value]="option">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>{{ getValue(option) }}</span>
          <button
            (click)="$event.stopPropagation(); onOptionDeleted(option)"
            *ngIf="showDelete && !last"
            mat-icon-button
          >
            <mat-icon class="no-pad">delete</mat-icon>
          </button>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
