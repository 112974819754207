import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Icons } from '@core/enums/icons';
import {
  ConnectionState,
  DisconnectReasons,
  SocketIoClientService,
} from '@core/services/socket.io/socket-io-client.service';

@Component({
  selector: 'recrewt-socketio-connection-indicator',
  templateUrl: './socketio-connection-indicator.component.html',
  styleUrls: ['./socketio-connection-indicator.component.scss'],
})
export class SocketioConnectionIndicatorComponent {
  disconnectionReasons = DisconnectReasons;

  icons = Icons;

  indicatorText: string = '';

  reason$: Observable<DisconnectReasons | undefined> = this.socket
    .getConnectionState()
    .pipe(map((x) => x.reason));

  connected$: Observable<boolean> = this.socket.getConnectionState().pipe(
    map((x) => {
      this.setErrorText(x);
      return x.connected;
    }),
  );

  constructor(private socket: SocketIoClientService) {}

  private setErrorText(x: ConnectionState) {
    switch (x.reason) {
      case DisconnectReasons.PING_TIMEOUT:
      case DisconnectReasons.SERVER_DISCONNECTED:
      case DisconnectReasons.TRANSPORT_ERROR:
      case DisconnectReasons.TRANSPORT_CLOSE:
        this.indicatorText = 'SOCKET_IO.CONNECTION_INDICATOR.reconnecting';
        break;
      case DisconnectReasons.UNKNOWN:
      case DisconnectReasons.CLIENT_DISCONNECTED:
      default:
        this.indicatorText = 'SOCKET_IO.CONNECTION_INDICATOR.error';
    }
  }
}
