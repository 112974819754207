import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

let DE_LETTERS_PATTERN: string = '[a-zA-ZäöüÄÖÜß ]*';
let TEXT_PATTERN: RegExp = /[\d\p{Letter}\p{Mark}\p{Space_Separator}\p{Punctuation}]+/gu;
// unicode categories: https://www.regular-expressions.info/unicode.html#category

let DIGITS_PATTERN: string = '\\d*';

export class RecrewtValidators {
  public static DE_LETTERS_ONLY: ValidatorFn = Validators.pattern(DE_LETTERS_PATTERN);

  public static TEXT: ValidatorFn = Validators.pattern(TEXT_PATTERN);

  public static NUMBERS: ValidatorFn = Validators.pattern(DIGITS_PATTERN);

  public static PLZ: ValidatorFn = Validators.compose([
    this.NUMBERS,
    Validators.maxLength(5),
    Validators.minLength(5),
  ])!;

  public static NAME: ValidatorFn = Validators.compose([
    this.DE_LETTERS_ONLY,
    Validators.maxLength(32),
    Validators.minLength(2),
  ])!;

  public static PHONE_NR: ValidatorFn = Validators.compose([
    Validators.pattern('(\\+)?\\d*'),
    Validators.required,
    Validators.maxLength(32),
    Validators.minLength(2),
  ])!;

  public static URL: ValidatorFn = Validators.pattern('^https?://.*');

  public static HEX_COLOR: ValidatorFn = Validators.pattern('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

  public static dateAfterToday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = control?.value;
      return date && date < new Date() ? { dateAfterToday: true } : null;
    };
  }

  public static toDateAfterFromDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromDate = control?.parent?.get('fromDate')?.value;
      const toDate = control?.value;
      return fromDate && toDate && fromDate > toDate ? { toDateAfterFromDate: true } : null;
    };
  }

  public static endAfterStart(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromDate = moment(control?.parent?.get('start')?.value);
      const toDate = moment(control?.parent?.get('end')?.value);
      const error = fromDate && toDate && fromDate > toDate ? { toDateAfterFromDate: true } : null;
      control?.parent?.get('end')?.setErrors(error);
      control?.parent?.get('start')?.setErrors(error);
      return error;
    };
  }

  public static fromList(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = typeof control.value === 'string';
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  public static requiredIf(condition: boolean): ValidatorFn | null {
    return condition ? Validators.required : Validators.nullValidator;
  }
}
