import { Component, OnInit } from '@angular/core';
import { UserQuery } from '@data/user/user.query';
import { AuthService } from '@auth0/auth0-angular';
import { EMPTY, Observable } from 'rxjs';
import { User } from '@data/user/user.model';
import { resetStores } from '@datorama/akita';
import { appRouteNames } from '../../app.routing.names';
import { TeamQuery } from '@data/team/team.query';
import { TeamWithPrivileges } from '@data/team/team.model';
import { UserActions } from '@data/user/user.actions';
import { Router } from '@angular/router';
import { TeamService } from '@data/team/team.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { TeamDialogComponent } from '@shared/components/dialog/team-dialog/team-dialog.component';

@Component({
  selector: 'recrewt-toolbar-layout',
  templateUrl: './toolbar-layout.component.html',
  styleUrls: ['./toolbar-layout.component.scss'],
})
export class ToolbarLayoutComponent implements OnInit {
  logoWithName = 'assets/images/RC_Logo_white.svg';

  profileRoute = '/' + appRouteNames.PROFILE;

  user$: Observable<User> = EMPTY;

  teams$: Observable<TeamWithPrivileges[]> = EMPTY;

  activeTeam$: Observable<TeamWithPrivileges> = EMPTY;

  title: string = 'Sessions';

  constructor(
    private userQuery: UserQuery,
    private auth0: AuthService,
    private teamQuery: TeamQuery,
    private teamService: TeamService,
    private router: Router,
    private teamDialog: DialogService<TeamDialogComponent, any>,
  ) {}

  ngOnInit(): void {
    this.user$ = this.userQuery.select();
    this.teams$ = this.teamQuery.selectAll();
    this.activeTeam$ = this.teamQuery.selectOnlyTruthyActiveTeam$;
  }

  logout(): void {
    resetStores();
    this.auth0.logout();
  }

  changeTeam(team: string): void {
    UserActions.updateUser({ activeTeam: team });
    this.router.navigate([appRouteNames.SESSION_LIST]).then(() => window.location.reload());
  }

  editTeam(): void {
    const team$ = this.teamService.getMembers();
    this.teamDialog.open(TeamDialogComponent, { team$ });
    this.teamDialog.onConfirmed().subscribe(() => {
      window.location.reload();
    });
  }
}
