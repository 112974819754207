import { AbstractForm } from '../abstract-form';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RecrewtValidators } from '@shared/util/forms.util';
import { ScholasticData } from '@data/attendant/attendant.model';

export class JobScholasticForm extends AbstractForm<ScholasticData> {
  harvest(formData: any): Partial<ScholasticData> {
    return formData;
  }

  protected buildForm(
    scholasticData: ScholasticData | undefined,
    fb: UntypedFormBuilder,
  ): UntypedFormGroup {
    return fb.group({
      schoolName: [scholasticData?.schoolName],
      schoolLocation: [scholasticData?.schoolLocation, RecrewtValidators.fromList()],
      courseId: [scholasticData?.courseId],
      courseName: [scholasticData?.courseName],
      jobOpportunities: [scholasticData?.jobOpportunities],
      phases: [scholasticData?.phases],
      degree: [scholasticData?.degree],
      semesterAbroad: [scholasticData?.semesterAbroad ?? false],
    });
  }
}
