import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-type-item',
  templateUrl: './type-item.component.html',
  styleUrls: ['./type-item.component.scss'],
})
export class TypeItemComponent {
  @Input() icon = 'add';

  @Input() desc: string | null = null;

  @Input() iconSet?: string;

  @Input() title = '';
}
