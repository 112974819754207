import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Filter } from './filter';
import { SessionsFilter } from '@data/filter/filters/sessions.filter';
import { ParticipantsFilter } from '@data/filter/filters/participants.filter';
import { AttendantsFilter } from '@data/filter/filters/attendants.filter';
import { MatchesFilter } from '@data/filter/filters/matches.filter';
import createInitialAttendantFilterState = AttendantsFilter.createInitialAttendantFilterState;
import createInitialUsersFilterState = ParticipantsFilter.createInitialUsersFilterState;
import createInitialSessionsFilterState = SessionsFilter.createInitialSessionsFilterState;
import createInitialMatchesFilterState = MatchesFilter.createInitialMatchesFilterState;

/** Filter type selection for different tables. */
export type FilterTarget = 'sessions' | 'participants' | 'attendants' | 'matches';

export type FilterState = {
  [key in FilterTarget]: Filter<any, any>[];
};

export function deserialize(persistState: any) {
  return {
    sessions: createInitialSessionsFilterState(persistState?.sessions ?? []),
    participants: createInitialUsersFilterState(persistState?.participants ?? []),
    attendants: createInitialAttendantFilterState(persistState?.attendants ?? []),
    matches: createInitialMatchesFilterState(persistState?.matches ?? []),
  };
}

export function createInitialState(): FilterState {
  return {
    participants: createInitialUsersFilterState([]),
    sessions: createInitialSessionsFilterState([]),
    attendants: createInitialAttendantFilterState([]),
    matches: createInitialMatchesFilterState([]),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filter' })
export class FilterStore extends Store<FilterState> {
  constructor() {
    super(createInitialState());
  }
}
