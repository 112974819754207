<h1 mat-dialog-title>{{ 'IMAGE_CROPPER.headline' | translate }}</h1>
<div mat-dialog-content>
  <div class="width-match-parent">
    <div class="width-match-parent">
      <image-cropper
        (imageCropped)="onImageCropped($event)"
        (imageLoaded)="onImageLoaded($event)"
        (loadImageFailed)="loading = false"
        [alignImage]="'center'"
        [aspectRatio]="data.aspectRatio"
        [autoCrop]="false"
        [backgroundColor]="backgroundColor"
        [containWithinAspectRatio]="data.roundCropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="data.maintainAspectRatio"
        [roundCropper]="data.roundCropper"
        format="png"
      ></image-cropper>
    </div>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="noActionClicked()" [disabled]="loading || committing" mat-button>
    {{ 'COMMON.cancel' | translate }}
  </button>
  <recrewt-image-selection-dialog-button
    (imageSelected)="onImageChanged($event)"
    [disabled]="loading || committing"
    class="m-r-1"
  ></recrewt-image-selection-dialog-button>
  <button (click)="onDelete()" [disabled]="loading || committing" color="warn" mat-flat-button>
    {{ 'COMMON.delete' | translate }}
  </button>
  <button (click)="onConfirm()" [disabled]="loading || committing" color="accent" mat-flat-button>
    {{ 'COMMON.confirm' | translate }}
  </button>
</div>
