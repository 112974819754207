import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Attendant } from '@data/attendant/attendant.model';

export interface AttendantState
  extends EntityState<Attendant, Attendant['id']>,
    ActiveState<Attendant> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attendant', resettable: true, idKey: 'id' })
export class AttendantStore extends EntityStore<AttendantState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  override akitaPreAddEntity(entity: Attendant) {
    return {
      ...entity,
      toDate: entity.toDate ? new Date(entity.toDate) : undefined,
      fromDate: entity.fromDate ? new Date(entity.fromDate) : undefined,
    };
  }
}
