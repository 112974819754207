import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TeamState, TeamStore } from '@data/team/team.store';
import { ObservableUtil } from '@shared/util/observable.util';
import { TeamWithPrivileges } from '@data/team/team.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamQuery extends QueryEntity<TeamState> {
  readonly selectWhenTruthyActiveTeam$: Observable<TeamWithPrivileges> =
    ObservableUtil.takeWhenTruthy(this.selectActive());

  readonly selectOnlyTruthyActiveTeam$: Observable<TeamWithPrivileges> =
    ObservableUtil.takeOnlyTruthy(this.selectActive());

  constructor(protected override store: TeamStore) {
    super(store);
  }
}
