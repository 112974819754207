/** usage log.call(this,...) */
export function log(this: any, obj: any, message: string = '') {
  let msg = `[${this.constructor.name}] `;

  // @ts-ignore
  if (!!message) msg += message;
  msg += ': ';

  if (typeof obj === 'string') {
    console.log(msg + obj);
    return;
  }
  console.log(msg);
  console.log(obj);
}
