import { combineLatest, first, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isEmpty } from 'lodash';

export class ObservableUtil {
  public static combineBoolean(...o: Observable<boolean>[]): Observable<boolean> {
    return combineLatest(o).pipe(
      map((os) => {
        let val = false;
        for (const observable of os) {
          val = val || observable;
        }
        return val;
      }),
    );
  }

  /** Takes only first truthy */
  public static takeWhenTruthy<T>(
    o: Observable<T | undefined | null>,
    predicate = (it: any) => !!it && !isEmpty(it),
  ): Observable<T> {
    return o.pipe(
      first((it) => predicate(it)),
      map((it) => it!),
    );
  }

  /** Filters out only truthy */
  public static takeOnlyTruthy<T>(
    o: Observable<T | undefined | null>,
    predicate = (it: any) => !!it && !isEmpty(it),
  ): Observable<T> {
    return o.pipe(
      filter((it) => predicate(it)),
      map((it) => it!),
    );
  }
}
