import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() active = false;

  @Input() title = 'Title.';

  @Input() description = '';

  @Input() iconId = 'business';

  @Output() sectionClicked = new EventEmitter<any>();

  onSectionClicked(): void {
    this.sectionClicked.emit();
  }
}
