import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { SocketEvents } from '@core/services/socket.io/socket-io-client.service';

@Injectable({
  providedIn: 'root',
})
export class SocketIoWrapperService {
  private socket: Socket | undefined;

  disconnect() {
    this.socket?.disconnect();
  }

  connected() {
    return this.socket?.connected ?? false;
  }

  connect(id?: string, name?: string) {
    if (!this.socket?.connected) {
      this.socket = io(environment.SOCKET_ENDPOINT, {
        auth: {
          token: {
            team: id,
            userName: name,
          },
        },
        transports: ['websocket'],
      });
    } else {
      //user changed without disconnecting from socketio???
      console.log('Already connected'); // TODO: Error handling.
    }
  }

  reconnect() {
    this.socket?.connect();
  }

  emit(socketEvent: SocketEvents, params: {}) {
    if (!!this.socket) {
      this.socket.emit(socketEvent, params);
    } else {
      console.log('Not connected to ws server'); // TODO: Error handling.
    }
  }

  on(socketEvent: SocketEvents, callback: (data?: any) => void) {
    this.socket?.on(socketEvent, callback);
  }
}
