import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from './user.model';

export type UserState = User;

function createInitialState(): UserState {
  return { rbacRoles: [], email: '', id: '' };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }

  userHasChanged(uid?: string): boolean {
    return this.getValue()?.id?.toString() !== uid;
  }
}
