import { Filter, ListFilter, NumberFilter, StringFilter } from '@data/filter/filter';
import { Match, MatchStatus } from '@data/match/match.model';
import { getMatchStatusTag } from '@shared/components/filter/components/status-tag/statuses/match-status';

export module MatchesFilter {
  export function createInitialMatchesFilterState(state: any[]): Filter<any, any>[] {
    return [
      new StringFilter<Match>(
        'Ausbildungsberuf',
        (it) => it.matchDescription,
        state[0]?.isActive,
        state[0]?.value,
        state[0]?.options,
      ),
      new StringFilter<Match>(
        'Firmenname',
        (it) => it.companyName,
        state[1]?.isActive,
        state[1]?.value,
        state[1]?.options,
      ),
      new NumberFilter<Match>(
        'Match Score',
        (it) => it.score * 100,
        state[2]?.isActive,
        state[2]?.value,
        state[2]?.options,
      ),
      new ListFilter<Match, MatchStatus>(
        [
          MatchStatus.MATCH,
          MatchStatus.LIKE,
          MatchStatus.HIGHLIKE,
          MatchStatus.DISLIKE,
          MatchStatus.TRASH,
          MatchStatus.APPLICATION,
        ],
        'Status',
        (m) => m.status,
        (it) => getMatchStatusTag(it)?.text ?? '?',
        state[3]?.isActive,
        state[3]?.value,
        state[3]?.options,
      ),
    ];
  }
}
