import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-full-screen-loading',
  templateUrl: './full-screen-loading.component.html',
  styleUrls: ['./full-screen-loading.component.scss'],
})
export class FullScreenLoadingComponent {
  @Input() text = '';

  @Input() asOverlay = true;
}
