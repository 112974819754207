<div>
  <div #origin (click)="open(popup, origin)">
    <button
      [matBadgeHidden]="!activeAmount"
      [matBadge]="activeAmount"
      mat-raised-button
      color=""
      matBadgeSize="small"
      type="button"
    >
      <mat-icon class="no-pad m-r-1">filter_list</mat-icon>
      {{ 'COMMON.filter' | translate }}
    </button>
  </div>
  <ng-template #popup>
    <div class="popup mat-elevation-z3">
      <ng-container>
        <recrewt-filter-group (apply)="onApply()" [filters]="filters"></recrewt-filter-group>
      </ng-container>
    </div>
  </ng-template>
</div>
