import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Team, TeamWithMembers, TeamWithPrivileges } from '@data/team/team.model';

export interface TeamState
  extends EntityState<TeamWithPrivileges & TeamWithMembers, Team['_id']>,
    ActiveState<Team['_id']> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teams', resettable: true, idKey: '_id' })
export class TeamStore extends EntityStore<TeamState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
