import { Injectable } from '@angular/core';
import {
  ActiveState,
  arrayRemove,
  arrayUpsert,
  EntityState,
  EntityStore,
  getEntityType,
  StoreConfig,
} from '@datorama/akita';
import { MatchingSession, SessionUser } from '@data/session/session.model';

export interface MatchingSessionState
  extends EntityState<MatchingSession, MatchingSession['id']>,
    ActiveState<MatchingSession['id']> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sessions', resettable: true })
export class SessionStore extends EntityStore<MatchingSessionState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  override akitaPreAddEntity(newEntity: any): getEntityType<MatchingSessionState> {
    if (!!newEntity) {
      const transformed: MatchingSession = {
        ...newEntity,
        users: newEntity.users.map((x: string) => <SessionUser>{ _id: x, name: '' }),
      };

      return super.akitaPreAddEntity(transformed);
    }
    return super.akitaPreAddEntity(newEntity);
  }

  public addUserToSession(sessionId: string, joinedParticipant: SessionUser) {
    this.update(sessionId, (session) => ({
      users: arrayUpsert(session.users, joinedParticipant._id, joinedParticipant, '_id'),
    }));
  }

  removeUserFromSession(sessionId: string, participantId: string) {
    this.update(sessionId, ({ users }) => ({
      users: arrayRemove(users, participantId, '_id'),
    }));
  }
}
