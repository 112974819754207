<ng-container *ngIf="!loading; else loadingPlaceholder">
  <ng-container *ngIf="!!_dataSource?.data?.length; else emptyPage" class="table-responsive">
    <mat-table
      (matSortChange)="sortChanged($event)"
      [dataSource]="_dataSource"
      class="width-match-parent"
      matSort
    >
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <mat-header-cell
          *matHeaderCellDef
          [disabled]="cellTemplates.get(column)?.sortingDisabled"
          [fxHide.lt-md]="(column | fn: get).hideLtMd"
          [fxHide.lt-sm]="(column | fn: get).hideLtSm || (column | fn: get).hideLtMd"
          mat-sort-header
          rememberSort
        >
          {{ cellTemplates.get(column)?.columnTitle ?? '' | translate | uppercase }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [fxHide.lt-md]="(column | fn: get).hideLtMd"
          [fxHide.lt-sm]="(column | fn: get).hideLtSm || (column | fn: get).hideLtMd"
        >
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: row }"
            [ngTemplateOutlet]="cellTemplates.get(column)?.templateRef ?? null"
          ></ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        (click)="onRowClick(row, $event)"
        (contextmenu)="onRightClick(row, $event)"
        *matRowDef="let row; columns: displayedColumns"
        [class.hover]="showHover"
        [class.pointer]="showHover"
        [ngClass]="{
          highlight: highlightedRowsId === row.id || this.selectedRows.has(row),
          'fade-out': highlightedRowsId === row.id
        }"
      ></mat-row>
    </mat-table>
  </ng-container>
</ng-container>
<div [hidden]="!_dataSource.data.length || loading">
  <mat-paginator [hidePageSize]="true" [pageSize]="20"></mat-paginator>
</div>

<ng-template #emptyPage>
  <recrewt-empty-page (buttonClick)="emptyClick.emit()" [config]="empty"></recrewt-empty-page>
</ng-template>

<ng-template #loadingPlaceholder>
  <div class="m-t-3 p-h-3">
    <div class="m-b-2">
      <ngx-skeleton-loader [theme]="{ height: '12px' }"></ngx-skeleton-loader>
    </div>
    <ng-container *ngFor="let i of 6 | iterable">
      <div fxLayout="column" style="height: 40px">
        <ngx-skeleton-loader [theme]="{ marginBottom: '4px', padding: 0 }"></ngx-skeleton-loader>
      </div>
    </ng-container>
  </div>
</ng-template>
