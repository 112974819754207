import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserState, UserStore } from '@data/user/user.store';
import { Observable, OperatorFunction } from 'rxjs';
import { User } from '@data/user/user.model';
import { filter, map } from 'rxjs/operators';
import { UserRbacRole } from '@core/enums/user-role';
import { ObservableUtil } from '@shared/util/observable.util';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  selectUser$: Observable<User> = this.select().pipe(this.emptyUserFilter());

  selectUserWhenTruthy$ = ObservableUtil.takeWhenTruthy(this.selectUser$);

  selectAccountRoles$ = this.selectUser$.pipe(map((user) => user.rbacRoles));

  constructor(protected override store: UserStore) {
    super(store);
  }

  public isFairsAdmin(): boolean {
    return this.fairsAdminMap(this.getValue()?.rbacRoles);
  }

  private emptyUserFilter(): OperatorFunction<User | undefined, User> {
    return filter((x) => {
      return !!x?.id;
    }) as OperatorFunction<User | undefined, User>;
  }

  private fairsAdminMap = (roles: string[]): boolean => {
    return roles.includes(UserRbacRole.ADMIN);
  };

  selectIsFairsAdmin$: Observable<boolean> = this.selectAccountRoles$.pipe(map(this.fairsAdminMap));
}
