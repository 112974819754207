export enum Icons {
  plus = 'add',
  eye = 'remove_red_eye',
  connected = 'wifi',
  disconnected = 'wifi_off',
  close = 'close',
  edit = 'edit',
  menu = 'menu',
  sync = 'sync',
  export = 'publish',
  import = 'file_open',
  share = 'share',
}
