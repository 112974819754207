import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[recrewtDnd]',
})
export class DndDirective {
  @HostBinding('class.fileover') fileover: boolean = false;

  @Output() dropped = new EventEmitter<DataTransfer>();

  @HostListener('dragover', ['$event '])
  onDragover(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileover = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileover = false;
    const files = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      this.dropped.emit(evt.dataTransfer);
    }
  }
}
