import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EntityCrudService, IHTTPParamsObject } from '@data/entity-crud.service';
import {
  Attendant,
  ICreateExternalAttendantInput,
  IUpdateAttendantChanges,
} from '@data/attendant/attendant.model';
import { dropUndefined } from '@shared/util/array.util';

@Injectable({
  providedIn: 'root',
})
export class AttendantService extends EntityCrudService<
  ICreateExternalAttendantInput | IUpdateAttendantChanges,
  Attendant
> {
  constructor(http: HttpClient) {
    super('sessions', http, 'attendants');
  }

  override getById(id: string, params?: IHTTPParamsObject, usePublic = false) {
    const httpParams = new HttpParams({ fromObject: dropUndefined(params) });
    const url = usePublic ? this.URL_PUBLIC : this.URL_PRIVATE;
    return this.http.get<Attendant>(`${url}/attendants/${id}`, { params: httpParams });
  }

  sendEditLink(sid: string, aid: string, email: string) {
    return this.http.post(`${this.URL_PUBLIC}/${sid}/attendants/${aid}/mail-edit`, { email });
  }

  sendEditLinkRequest(sid: string, data: { email: string; projects: string; sessionName: string }) {
    return this.http.post(`${this.URL_PUBLIC}/${sid}/mail-edit-request`, data);
  }

  exportToCompanySuite(aid: string) {
    return this.http.post(`${this.URL_PRIVATE}/attendants/${aid}/export`, null);
  }
}
