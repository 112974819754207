import { Filter, ListFilter, NumberFilter, StringFilter } from '@data/filter/filter';
import { AttendantWithCompany, getJobsAmount } from '@data/attendant/attendant.model';

export module AttendantsFilter {
  export function createInitialAttendantFilterState(state: any[]): Filter<any, any>[] {
    return [
      new StringFilter<AttendantWithCompany>(
        'Firmenname',
        (it) => it.company?.companyData.name,
        state[0]?.isActive,
        state[0]?.value,
        state[0]?.options,
      ),
      new StringFilter<AttendantWithCompany>(
        'Stand',
        ({ attendant }) => attendant.boothNr,
        state[1]?.isActive,
        state[1]?.value,
        state[1]?.options,
      ),
      new NumberFilter<AttendantWithCompany>(
        'Jobs',
        ({ attendant }) => getJobsAmount(attendant),
        state[2]?.isActive,
        state[2]?.value,
        state[2]?.options,
      ),
      new ListFilter<AttendantWithCompany, boolean>(
        [true, false],
        'Anwesend',
        ({ attendant }) => !attendant.exited,
        (exited) => 'COMMON.' + (exited ? 'yes' : 'no'),
        state[3]?.isActive,
        state[3]?.value,
        state[3]?.options,
      ),
      new ListFilter<AttendantWithCompany, boolean>(
        [true, false],
        'Überprüft',
        ({ attendant }) => attendant.checked,
        (checked) => 'COMMON.' + (checked ? 'yes' : 'no'),
        state[4]?.isActive,
        state[4]?.value,
        state[4]?.options,
      ),
    ];
  }
}
