import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { TeamActions } from '@data/team/team.actions';
import { TeamStore } from '@data/team/team.store';
import { TeamService } from '@data/team/team.service';
import { HttpUtils } from '@shared/util/http.util';
import { UserQuery } from '@data/user/user.query';

@Injectable({ providedIn: 'root' })
export class TeamEffects {
  load = createEffect((actions) =>
    actions.pipe(
      ofType(TeamActions.load),
      switchMap(() => this.userQuery.selectUserWhenTruthy$),
      switchMap(() => this.teamService.get().pipe(HttpUtils.catchAndPrintError())),
      tap((teams) => {
        const activeTeam = this.userQuery.getValue().activeTeam;
        this.teamStore.set(teams ?? []);
        this.teamStore.setActive(activeTeam ?? teams[0]?._id);
      }),
    ),
  );

  invite = createEffect((actions) =>
    actions.pipe(
      ofType(TeamActions.invite),
      switchMap((request) => this.teamService.invite(request).pipe(HttpUtils.catchAndPrintError())),
      tap((teams) => {
        if (!teams) return;
        this.teamStore.set(teams);
      }),
    ),
  );

  constructor(
    private teamStore: TeamStore,
    private teamService: TeamService,
    private userQuery: UserQuery,
  ) {}
}
