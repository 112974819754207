<div class="height-match-parent" style="max-width: 100vw">
  <mat-toolbar class="width-match-parent">
    <mat-toolbar-row
      class="first-toolbar-row width-match-parent no-select mat-elevation-z2"
      fxLayoutAlign="space-between center"
    >
      <div fxLayoutAlign="center center">
        <a *ngIf="logoWithName" routerLink="/">
          <img [src]="logoWithName" alt="Recrewt Logo" class="nav-home-logo" />
        </a>
      </div>

      <div *ngIf="true" class="m-r-s" fxLayoutAlign="start center">
        <ng-container *ngIf="activeTeam$ | async as activeTeam">
          <button [matMenuTriggerFor]="teamMenu" class="m-r-1" color="primary" mat-flat-button>
            {{ (activeTeam$ | async)?.name ?? 'TOOLBAR.USER.select_team' | translate }}
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #teamMenu="matMenu">
            <button [matMenuTriggerFor]="teams" mat-menu-item>
              <mat-icon>groups</mat-icon>
              {{ 'TOOLBAR.USER.change_team' | translate }}
            </button>
            <button (click)="editTeam()" *ngIf="activeTeam?.privileges?.role === 0" mat-menu-item>
              <mat-icon>edit</mat-icon>
              {{ 'TOOLBAR.USER.edit_team' | translate }}
            </button>
          </mat-menu>

          <mat-menu #teams="matMenu">
            <button
              (click)="changeTeam(team._id)"
              *ngFor="let team of teams$ | async"
              [class.text-weight-bold]="team._id === activeTeam._id"
              [disabled]="team._id === activeTeam._id"
              mat-menu-item
            >
              {{ team._id === activeTeam._id ? '✓' : '' }} {{ team.name }}
            </button>
          </mat-menu>
        </ng-container>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <recrewt-name-letter-avatar
            *ngIf="(user$ | async)?.email as email"
            [name]="email"
            size="medium"
          ></recrewt-name-letter-avatar>
        </button>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="second-toolbar-row width-match-parent">
      <recrewt-breadcrumbs class="width-match-parent"></recrewt-breadcrumbs>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="toolbar-content">
    <div class="nav-content height-match-parent">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="{{ profileRoute }}">
    <mat-icon>edit</mat-icon>
    <span>{{ 'TOOLBAR.USER.profile' | translate }}</span>
  </button>

  <button (click)="logout()" mat-menu-item>
    <mat-icon>logout</mat-icon>
    <span>{{ 'TOOLBAR.USER.logout' | translate }}</span>
  </button>
</mat-menu>
