import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
})
export class InfoSectionComponent {
  @Input() icon: string | 'recrewt' = 'info';

  @Input() headline = '';

  @Input() text = '';

  @Input() buttonIcon?: string;

  @Input() buttonText = '';

  @Output() buttonClicked = new EventEmitter();

  @Input() dynamicText: string = '';
}
