import { AfterViewInit, Directive, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-checkbox[control]',
  exportAs: 'checkboxValue',
})
export class CheckboxValueDirective implements AfterViewInit {
  @Input() control?: FormControl;

  @Input() falseValue: any = '0';

  @Input() trueValue: any = '1';

  constructor(private checkbox: MatCheckbox) {}

  ngAfterViewInit() {
    this.control?.setValue(this.checkbox?.checked ? this.trueValue : this.falseValue);
    this.checkbox?.registerOnChange(() => {
      this.control?.setValue(this.checkbox?.checked ? this.trueValue : this.falseValue);
    });
  }
}
