import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[takeFocus]',
})
export class TakeFocusDirective implements OnInit {
  constructor(private el: ElementRef) {
    if (!el.nativeElement.focus) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngOnInit(): void {
    const input: HTMLElement = this.el.nativeElement as HTMLElement;
    input.focus();
  }
}
