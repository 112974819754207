<div fxLayoutAlign="space-between start">
  <div fxLayout="column">
    <h1 mat-dialog-title>
      {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_edit' | translate }}
    </h1>
  </div>
  <button [mat-dialog-close]="null" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div fxLayout="column" mat-dialog-content>
  <div class="p-v-1 p-h-2 accent-bg-low b-rad-1 m-b-3">
    <p class="no-pad mat-body-2 width-match-parent" ngClass.lt-md="mat-caption">
      Bei Fragen wenden Sie sich gerne jederzeit an unser Crew Mitglied Bianca Kujath.<br />
      E-Mail: <a href="mailto:bianca.kujath@recrewt.de" tabindex="-1">bianca.kujath@recrewt.de</a
      ><br />
      Tel: <a href="tel:+49 160 2582637" tabindex="-1">+49 160 2582637</a>
    </p>
  </div>
  <p class="on-surface-medium">
    {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_edit_desc' | translate }}
  </p>
  <h3>Allgemeines</h3>
  <div [formGroup]="form.form" fxFlex="grow" fxLayout="column">
    <div fxFlex="grow" fxLayout.lt-md="column" fxLayoutAlign="start baseline" fxLayoutGap="16px">
      <recrewt-select
        (selectChange)="updateIdentifiableName($event)"
        [displayWith]="displayValue"
        [mapValueTo]="trackById"
        [matStyle]="true"
        [model]="jobGeneralById(data.currentJob?.id)"
        [options]="data.jobs"
        [parentFormGroup]="form.form"
        [placeholder]="'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.select_job' | translate"
        [require]="true"
        class="width-match-parent"
        error="COMMON.INPUT_ERRORS.empty"
        formControlName="id"
        fxFlex="grow"
        labelKey="name.de"
      ></recrewt-select>

      <mat-form-field
        *ngIf="form.data?.id | fn: hasSpecializations | async"
        appearance="outline"
        class="width-match-parent"
        fxFlex="grow"
      >
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_spec' | translate
        }}</mat-label>
        <mat-select formControlName="specializations" multiple>
          <mat-option
            *ngFor="let spec of form.data?.id | fn: getSpecializations | async"
            [value]="spec.id"
            >{{ spec.name.de }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" fxFlex="grow">
      <input formControlName="identifiableName" id="identifiableName" matInput />
      <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
      <mat-label>{{
        'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.identifiableName' | translate
      }}</mat-label>
    </mat-form-field>

    <div fxFlex="grow" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap.gt-sm="16px">
      <recrewt-select
        *allowInput="AllowedJobData.EDUCATION_LEVEL; values: data.session.allowJobData"
        [displayWith]="eduMapping"
        [idKey]="null"
        [matStyle]="true"
        [options]="EducationLevel"
        [parentFormGroup]="form.form"
        [require]="true"
        class="width-match-parent"
        error="COMMON.INPUT_ERRORS.select_from_list"
        formControlName="educationLevel"
        fxFlex="grow"
        placeholder="SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_edu"
      ></recrewt-select>
      <mat-form-field
        *allowInput="AllowedJobData.URL; values: data.session.allowJobData"
        appearance="outline"
        class="width-match-parent"
        fxFlex="grow"
      >
        <input (blur)="appendHttps()" formControlName="url" id="url" matInput />
        <mat-error>{{ 'COMMON.INPUT_ERRORS.url' | translate }}</mat-error>
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_url' | translate
        }}</mat-label>
      </mat-form-field>
    </div>

    <mat-form-field
      *allowInput="AllowedJobData.DESC; values: data.session.allowJobData"
      appearance="outline"
      fxFlex="grow"
    >
      <textarea
        [required]="!!data.session.allowJobData?.includes(AllowedJobData.DESC)"
        cdkAutosizeMinRows="3"
        cdkTextareaAutosize
        formControlName="desc"
        id="desc"
        matInput
      ></textarea>
      <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
      <mat-label>{{
        'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_desc' | translate
      }}</mat-label>
    </mat-form-field>
    <mat-checkbox
      *allowInput="AllowedJobData.INTERNSHIP_POSSIBLE; values: data.session.allowJobData"
      class="width-match-parent m-b-2"
      formControlName="internshipPossible"
      >{{
        'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.internship_possible' | translate
      }}</mat-checkbox
    >
    <mat-divider class="width-match-parent m-v-2"></mat-divider>
    <div *allowInput="AllowedJobData.TOPICS; values: data.session.allowJobData">
      <h3 class="m-b-1">
        {{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.topics' | translate }}
      </h3>
      <div class="p-b-2">
        <mat-hint>
          Mit welchen Themen hat der/die Auszubildende in diesem Beruf zu tun? Mehrfachauswahl
          möglich.
        </mat-hint>
      </div>
      <recrewt-chip-editor
        [predefinedOnly]="true"
        [predefined]="TOPICS"
        class="p-b-2"
        formControlName="topics"
      ></recrewt-chip-editor>
      <mat-divider class="width-match-parent m-v-2"></mat-divider>
    </div>
  </div>
  <div
    *allowInput="AllowedJobData.SALARY; values: data.session.allowJobData"
    [formGroup]="benefitsForm.form"
    class="m-b-2"
    fxFlex="grow"
    fxLayout="column"
  >
    <h3>Gehalt pro Monat (brutto, in €)</h3>
    <ng-container *ngFor="let salary of salaryFormArray.controls; let i = index">
      <div
        formArrayName="estimatedSalaryEUR"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
      >
        <span style="width: 100px">{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.salary_year' | translate: { year: i + 1 }
        }}</span>
        <mat-form-field appearance="outline" fxFlex="grow">
          <input [formControlName]="i" autocomplete="off" digitOnly matInput type="number" />
          <mat-error>{{ 'COMMON.INPUT_ERRORS.less_than_zero' | translate }}</mat-error>
          <mat-label>{{
            'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.salary_amount' | translate
          }}</mat-label>
        </mat-form-field>
        <button (click)="salaryFormArray.removeAt(i)" mat-icon-button>
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </ng-container>
    <button (click)="addSalary()" mat-button type="button">
      <mat-icon>add</mat-icon>
      <span>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.add_salary' | translate }}</span>
    </button>
    <mat-divider class="width-match-parent m-v-2"></mat-divider>
  </div>
  <div class="m-b-2" fxFlex="grow" fxLayout="column">
    <ng-container *allowInput="AllowedJobData.VACATION; values: data.session.allowJobData">
      <h3>Urlaub</h3>
      <mat-form-field [formGroup]="form.form" appearance="outline" fxFlex="grow">
        <input formControlName="vacation" matInput type="number" />
        <mat-error>{{ 'COMMON.INPUT_ERRORS.less_than_zero' | translate }}</mat-error>
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.vacation' | translate
        }}</mat-label>
      </mat-form-field>
      <mat-divider class="width-match-parent m-v-2"></mat-divider>
    </ng-container>
    <ng-container *allowInput="AllowedJobData.BENEFITS; values: data.session.allowJobData">
      <h3>Leistungen/Benefits</h3>
      <p class="mat-hint">
        Hinweis: Benefits können auch frei und individuell eingegeben werden. Bitte mit Enter
        bestätigen.
      </p>
      <recrewt-categorical-selector-group
        [config]="benefits"
        [parentFormGroup]="benefitsFormGroup"
        class="width-match-parent"
      ></recrewt-categorical-selector-group>
    </ng-container>
    <mat-divider class="width-match-parent m-v-2"></mat-divider>
  </div>
  <div
    *allowInput="AllowedJobData.SCHOLASTIC_DATA; values: data.session.allowJobData"
    [formGroup]="scholasticDataForm.form"
    fxFlex="grow"
    fxLayout="column"
  >
    <div
      *ngIf="type === BookableType.COMPOUND_STUDY || type === BookableType.DUAL_STUDY"
      fxLayout="column"
    >
      <h3>{{ 'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.scholastic' | translate }}</h3>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.school' | translate
        }}</mat-label>
        <input formControlName="schoolName" matInput />
      </mat-form-field>
      <recrewt-location-selector
        formControlName="schoolLocation"
        label="SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.school_location"
      ></recrewt-location-selector>
      <recrewt-select
        (selectChange)="updateCourse($event)"
        *ngIf="type === BookableType.COMPOUND_STUDY"
        [displayWith]="displayValue"
        [mapValueTo]="trackById"
        [matStyle]="true"
        [model]="courseById(scholasticDataForm.data.courseId)"
        [options]="data.courses"
        [parentFormGroup]="scholasticDataForm.form"
        error="COMMON.INPUT_ERRORS.empty"
        formControlName="courseId"
        labelKey="name.de"
        placeholder="SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.course"
      ></recrewt-select>
      <mat-form-field appearance="outline">
        <input formControlName="courseName" matInput />
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.course_name' | translate
        }}</mat-label>
        <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="grow">
        <textarea
          cdkAutosizeMinRows="3"
          cdkTextareaAutosize
          formControlName="phases"
          id="phases"
          matInput
        ></textarea>
        <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.phases' | translate
        }}</mat-label>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.degree' | translate
        }}</mat-label>
        <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
        <input formControlName="degree" matInput />
      </mat-form-field>
      <div class="width-match-parent m-b-3">
        <mat-checkbox class="width-match-parent" formControlName="semesterAbroad">{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.semester_abroad' | translate
        }}</mat-checkbox>
      </div>
      <mat-form-field appearance="outline" fxFlex="grow">
        <textarea
          cdkAutosizeMinRows="3"
          cdkTextareaAutosize
          formControlName="jobOpportunities"
          id="jobOpportunities"
          matInput
        ></textarea>
        <mat-error>{{ 'COMMON.INPUT_ERRORS.empty' | translate }}</mat-error>
        <mat-label>{{
          'SESSIONS.RUNNING_SESSION.ADD_COMPANY_DIALOG.job_opportunities' | translate
        }}</mat-label>
      </mat-form-field>
    </div>
  </div>
  <div class="p-b-6" fxLayoutAlign="end center" mat-dialog-actions>
    <div>
      <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
      <button (click)="submit()" color="accent" mat-raised-button type="submit">
        {{ (data.currentJob ? 'COMMON.save' : 'COMMON.add') | translate }}
      </button>
    </div>
  </div>
</div>
