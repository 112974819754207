import moment from 'moment';
import {
  DateFilterOption,
  NumberFilterOption,
  StringFilterOption,
} from '@core/enums/filter-option';

export type FilterType = 'string' | 'number' | 'date' | 'list';

export class FilterOption<T, K extends string> {
  isActive = false;

  filterOption: K;

  private readonly filterFn: (it: T, val: T) => boolean;

  constructor(filterOption: K, filterFn: (it: T, val: T) => boolean) {
    this.filterOption = filterOption;
    this.filterFn = filterFn;
  }

  filter(item?: T, value?: T) {
    if (value == null || item == null) {
      return false;
    }
    return this.filterFn(item, value);
  }
}

export class ListFilterOption<T> extends FilterOption<T, string> {
  private readonly value: T;

  constructor(valueName: string, value: T) {
    super(valueName, (it, val) => it === val);
    this.value = value;
  }

  override filter(item: T): boolean {
    return super.filter(item, this.value);
  }
}

export abstract class Filter<T, K> {
  getFilterProp: (it: T) => any;

  isActive: boolean;

  name: string;

  value?: K;

  abstract type: FilterType;

  abstract options: FilterOption<K, any>[];

  constructor(name: string, filterPropAccessor: (it: T) => any, isActive?: boolean, value?: K) {
    this.getFilterProp = filterPropAccessor;
    this.name = name;
    this.isActive = !!isActive;
    this.value = value;
  }

  filter(data: T[]) {
    if (!this.isActive) {
      return data;
    }
    const filters = this.options.filter((opt) => opt?.isActive);
    return data.filter((it) => this.anyMatches(it, filters, this.value));
  }

  setValue(val: any): void {
    this.value = val;
  }

  private anyMatches(item: T, filters: FilterOption<K, any>[], value?: K): boolean {
    const prop = this.getFilterProp(item);
    return filters.reduce<boolean>(
      (anyMatches, filter) => anyMatches || filter.filter(prop, value),
      false,
    );
  }
}

export class StringFilter<T> extends Filter<T, string> {
  type: FilterType = 'string';

  options: FilterOption<string, StringFilterOption>[] = [
    new FilterOption(StringFilterOption.StartsWith, (it, val) => it.startsWith(val)),
    new FilterOption(StringFilterOption.EndsWith, (it, val) => it.endsWith(val)),
    new FilterOption(StringFilterOption.Equals, (it, val) => it === val),
    new FilterOption(StringFilterOption.NotEquals, (it, val) => it !== val),
    new FilterOption(StringFilterOption.Contains, (it, val) => it.includes(val)),
    new FilterOption(StringFilterOption.NotContains, (it, val) => !it.includes(val)),
  ];

  constructor(
    name: string,
    filterPropAccessor: (it: T) => any,
    isActive?: boolean,
    value?: string,
    optionData?: any[],
  ) {
    super(name, filterPropAccessor, isActive, value);
    if (optionData) {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].isActive = optionData[i]?.isActive;
      }
    }
  }
}

export class NumberFilter<T> extends Filter<T, number> {
  type: FilterType = 'number';

  options: FilterOption<number, NumberFilterOption>[] = [
    new FilterOption(NumberFilterOption.LessThan, (it, val) => it < val),
    new FilterOption(NumberFilterOption.LessThanEquals, (it, val) => it <= val),
    new FilterOption(NumberFilterOption.MoreThan, (it, val) => it > val),
    new FilterOption(NumberFilterOption.MoreThanEquals, (it, val) => it >= val),
    new FilterOption(NumberFilterOption.Equals, (it, val) => it === val),
  ];

  constructor(
    name: string,
    filterPropAccessor: (it: T) => any,
    isActive?: boolean,
    value?: number,
    optionData?: any[],
  ) {
    super(name, filterPropAccessor, isActive, value);
    if (optionData) {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].isActive = optionData[i]?.isActive;
      }
    }
  }
}

export class DateFilter<T> extends Filter<T, Date> {
  type: FilterType = 'date';

  options: FilterOption<Date, DateFilterOption>[] = [
    new FilterOption(DateFilterOption.Before, (it, val) => moment(it).isBefore(moment(val), 'day')),
    new FilterOption(DateFilterOption.After, (it, val) => moment(it).isAfter(moment(val), 'day')),
    new FilterOption(DateFilterOption.BeforeEquals, (it, val) =>
      moment(it).isSameOrBefore(moment(val), 'day'),
    ),
    new FilterOption(DateFilterOption.AfterEquals, (it, val) =>
      moment(it).isSameOrAfter(moment(val), 'day'),
    ),
    new FilterOption(DateFilterOption.Equals, (it, val) => moment(it).isSame(moment(val), 'day')),
  ];

  constructor(
    name: string,
    filterPropAccessor: (it: T) => any,
    isActive?: boolean,
    value?: Date,
    optionData?: any[],
  ) {
    super(name, filterPropAccessor, isActive, value);
    if (optionData) {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].isActive = optionData[i]?.isActive;
      }
    }
  }
}

export class ListFilter<T, K> extends Filter<T, K> {
  type: FilterType = 'list';

  options: ListFilterOption<K>[];

  constructor(
    data: K[],
    name: string,
    filterPropAccessor: (it: T) => any,
    filterNameAccessor: (it: K) => string,
    isActive?: boolean,
    value?: K,
    optionData?: any[],
  ) {
    super(name, filterPropAccessor, isActive, value);
    this.options = data.map((it) => {
      const propName: string = filterNameAccessor(it);
      return new ListFilterOption<K>(propName, it);
    });
    if (optionData) {
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].isActive = optionData[i]?.isActive;
      }
    }
  }

  override setValue(val: boolean[]): void {
    for (let i = 0; i < val.length; i++) {
      this.options[i].isActive = val[i];
    }
  }
}
