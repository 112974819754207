import { Injectable } from '@angular/core';
import { Institution, NewInstitution } from '@data/institution/institution.model';
import { HttpClient } from '@angular/common/http';
import { EntityCrudService } from '@data/entity-crud.service';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService extends EntityCrudService<NewInstitution, Institution> {
  constructor(http: HttpClient) {
    super('institutions', http);
  }
}
