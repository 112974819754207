<div class="content primary-bg no-overflow width-match-parent" fxLayout>
  <img
    alt="Decoration"
    class="decoration position-top-right on-primary-low tint-white"
    src="../../../assets/images/RC_Logo_Icon.svg"
  />
  <mat-card class="mat-elevation-z7 width-match-parent" ngClass.lt-md="p-3">
    <router-outlet></router-outlet>
  </mat-card>
</div>
<recrewt-footer></recrewt-footer>
