import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRouteNames } from './app.routing.names';
import { InfoCardLayoutComponent } from './layout/info-card-layout/info-card-layout.component';
import { PrimaryBgLayoutComponent } from './layout/primary-bg-layout/primary-bg-layout.component';
import { ToolbarLayoutComponent } from './layout/toolbar-layout/toolbar-layout.component';
import { FooterOnlyLayoutComponent } from './layout/footer-only-layout/footer-only-layout.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { TeamResolver } from '@core/router/resolvers/team.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: appRouteNames.SESSION_LIST,
    pathMatch: 'full',
  },
  {
    path: appRouteNames.LOGIN,
    component: FooterOnlyLayoutComponent,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: appRouteNames.PROFILE,
    canActivate: [AuthGuard],
    component: ToolbarLayoutComponent,
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },

  {
    path: appRouteNames.ERROR,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: appRouteNames.INFO,
    component: PrimaryBgLayoutComponent,
    loadChildren: () => import('./modules/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: appRouteNames.PUBLIC,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: appRouteNames.SESSION_LIST,
    canActivate: [AuthGuard],
    resolve: [TeamResolver],
    component: ToolbarLayoutComponent,
    loadChildren: () => import('./modules/sessions/sessions.module').then((m) => m.SessionsModule),
    data: { breadcrumb: 'Sessions' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
