import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'recrewt-image-selection-dialog-button',
  templateUrl: './image-selection-dialog-button.component.html',
  styleUrls: ['./image-selection-dialog-button.component.scss'],
})
export class ImageSelectionDialogButtonComponent {
  @Input() firstChange = false;

  @Input() disabled = false;

  @Output() imageSelected = new EventEmitter<any>();

  @Output() delete = new EventEmitter();

  @ViewChild('imgInput', { static: true }) imgInput?: ElementRef;

  @ViewChild('button', { static: true }) button?: MatButton;

  constructor(public deleteOrSelectDialog: DialogService<ConfirmDialogComponent, boolean | null>) {}

  @Input() displayCategoriesWith: (it: any) => string = (it) => it;

  click(): void {
    this.openDeleteOrSelectDialog();
  }

  private onChangeImageClicked(): void {
    this.openFileBrowser();
  }

  private openDeleteOrSelectDialog(): void {
    if (this.firstChange) {
      this.onChangeImageClicked();
      return;
    }
    const data = {
      altConfirmText: 'COMMON.delete',
      cancelText: 'COMMON.cancel',
      confirmColor: 'accent',
      confirmText: 'COMMON.edit',
      message: 'FILE_ELEMENTS.SELECT_DIALOG.message',
      title: 'FILE_ELEMENTS.SELECT_DIALOG.title',
    };
    this.deleteOrSelectDialog.open(ConfirmDialogComponent, data);
    this.deleteOrSelectDialog.dialogRef?.beforeClosed().subscribe((it) => {
      if (it == null) {
        return;
      } else if (it) {
        this.onChangeImageClicked();
      } else {
        this.delete.emit();
      }
    });
  }

  private openFileBrowser() {
    this.imgInput?.nativeElement.click();
  }
}
