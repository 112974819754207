import { actions, createAction, props } from '@ngneat/effects';
import { User } from '@data/user/user.model';

/* ID and Email not allowed on BE */
export type UpdateUser = Omit<User, 'id' | 'email' | 'rbacRoles'>;

export namespace UserActions {
  export const load = createAction('[User] Load user', props<{ roles: string[] }>());
  export const update = createAction('[User] Update user data', props<{ changes: UpdateUser }>());

  export const updateUser = (changes: UpdateUser): void => {
    actions.dispatch(update({ changes }));
  };
}
