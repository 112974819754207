import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Company } from '@data/company/company.model';

export interface CompanyState extends EntityState<Company, Company['id']>, ActiveState<Company> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company', resettable: true, idKey: 'id' })
export class CompanyStore extends EntityStore<CompanyState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
