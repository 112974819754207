<mat-form-field
  #matFormField
  *ngIf="useMatStyle"
  [class.no-hint]="!error"
  [formGroup]="locationForm"
  appearance="outline"
  class="on-surface"
  fxFlex="grow"
>
  <input
    #input
    (input)="searchControl.setValue(input.value)"
    [matAutocomplete]="auto"
    [placeholder]="label | translate"
    autocomplete="new-address"
    formControlName="location"
    matInput
    name="query"
  />
  <mat-error>{{ error | translate }}</mat-error>
  <mat-label>{{ label | translate }}</mat-label>
</mat-form-field>

<div *ngIf="!useMatStyle" [formGroup]="locationForm" fxFlex="grow">
  <input
    #input
    (input)="searchControl.setValue(input.value)"
    [matAutocomplete]="auto"
    [placeholder]="'LOCATION_SELECT.placeholder' | translate"
    autocomplete="new-address"
    class="no-pad p-1 mat-styled-input"
    formControlName="location"
    fxFlex="grow"
    name="query"
    takeFocus
  />
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="setFormFieldValue($event.option.value)"
  [displayWith]="displayValue"
>
  <mat-option *ngFor="let value of results$ | async" [value]="value">
    <mat-icon class="on-surface-medium">place</mat-icon>
    <span>{{ street(value) }}</span> |
    <small class="on-surface-medium">{{ city(value) }}</small>
  </mat-option>
</mat-autocomplete>
