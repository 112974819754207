import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[allowInput]',
})
export class AllowInputDirective<K> implements OnInit {
  private _values: K[] = [];

  private _key!: K;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input()
  set allowInputValues(value: K[] | undefined) {
    this._values = value ?? [];
  }

  @Input()
  set allowInput(key: K) {
    this._key = key;
  }

  ngOnInit() {
    if (!this._values.includes(this._key)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
