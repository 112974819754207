import { DateFilter, Filter, ListFilter, NumberFilter, StringFilter } from '@data/filter/filter';
import { MatchingSession } from '@data/session/session.model';
import { SessionStatus } from '@core/enums/session-status';
import { enumValues } from '@shared/util/enum.util';

export module SessionsFilter {
  export function createInitialSessionsFilterState(state: any[]): Filter<any, any>[] {
    return [
      new StringFilter<MatchingSession>(
        'Name',
        (it) => it.name,
        state[0]?.isActive,
        state[0]?.value,
        state[0]?.options,
      ),
      new ListFilter<MatchingSession, SessionStatus>(
        enumValues(SessionStatus),
        'Status',
        (it) => it.status,
        (status) => 'SESSIONS.TABLE.STATUS.' + SessionStatus[status].toLocaleLowerCase(),
        state[1]?.isActive,
        state[1]?.value,
        state[1]?.options,
      ),
      new DateFilter<MatchingSession>(
        'Startdatum',
        (it) => it.createdAt,
        state[2]?.isActive,
        state[2]?.value,
        state[2]?.options,
      ),
      new DateFilter<MatchingSession>(
        'Enddatum',
        (it) => it.closedAt,
        state[3]?.isActive,
        state[3]?.value,
        state[3]?.options,
      ),
      new NumberFilter<MatchingSession>(
        'Teilnehmer',
        (it) => it.users.length,
        state[4]?.isActive,
        state[4]?.value,
        state[4]?.options,
      ),
      new NumberFilter<MatchingSession>(
        'Pin',
        (it) => it.users.length,
        state[5]?.isActive,
        state[5]?.value,
        state[5]?.options,
      ),
    ];
  }
}
