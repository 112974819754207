import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { ParticipantStore } from '@data/participant/participant.store';
import { ParticipantService } from '@data/participant/participant.service';
import { ParticipantActions } from '@data/participant/participant.actions';
import { omitTypeFromRequest } from '@data/entity-crud.service';

@Injectable({ providedIn: 'root' })
export class ParticipantEffects {
  load = createEffect((actions) => {
    return actions.pipe(
      ofType(ParticipantActions.load),
      switchMap((request) => {
        this.participantStore.setLoading(true);
        return this.participantService.get(omitTypeFromRequest(request));
      }),
      tap((participants) => {
        this.participantStore.set(participants ?? []);
        this.participantStore.setLoading(false);
      }),
    );
  });

  loadById = createEffect((actions) =>
    actions.pipe(
      ofType(ParticipantActions.loadById),
      switchMap(({ participantId, sessionId, setActive }) =>
        this.participantService.getById(participantId, { sessionId: sessionId }).pipe(
          map((x) => {
            return { participant: x, setActive: setActive };
          }),
        ),
      ),
      tap(({ participant, setActive }) => {
        this.participantStore.setLoading(false);
        if (!participant) return;
        this.participantStore.upsert(participant._id, participant);
        if (setActive) {
          this.participantStore.setActive(participant._id);
        }
      }),
    ),
  );

  constructor(
    private participantStore: ParticipantStore,
    private participantService: ParticipantService,
  ) {}
}
