import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'recrewt-primary-bg-layout',
  templateUrl: './primary-bg-layout.component.html',
  styleUrls: ['./primary-bg-layout.component.scss'],
})
export class PrimaryBgLayoutComponent {
  constructor(public location: Location) {}
}
