import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICreateSessionRequest, MatchingSession } from '@data/session/session.model';
import { EntityCrudService } from '@data/entity-crud.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends EntityCrudService<ICreateSessionRequest, MatchingSession> {
  constructor(http: HttpClient) {
    super('sessions', http);
  }

  override create(data: ICreateSessionRequest, usePublic: boolean = false) {
    data.pin = !data.pin ? (Math.floor(Math.random() * 900000) + 100000).toString() : data.pin;
    return super.create(data, usePublic);
  }
}
