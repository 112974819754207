import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  durationInSeconds = 3;

  config: MatSnackBarConfig = {
    duration: this.durationInSeconds * 1000,
    horizontalPosition: 'end',
  };

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  error(message: string): void {
    this.config.panelClass = ['error-msg'];
    this.snackBar.open(this.translate.instant(message), 'OK', this.config);
  }

  success(message: string): void {
    this.config.panelClass = ['success'];
    this.snackBar.open(this.translate.instant(message), 'OK', this.config);
  }

  info(message: string): void {
    this.config.panelClass = ['info'];
    this.snackBar.open(this.translate.instant(message), 'OK', this.config);
  }
}
