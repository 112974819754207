import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserState } from '@data/user/user.store';
import { User } from '@data/user/user.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = `${environment.apiUrl}/private/user`;

  constructor(private http: HttpClient) {}

  get(): Observable<UserState | undefined> {
    return this.http.get<UserState>(`${this.url}`);
  }

  update(changes: Partial<User>) {
    return this.http.put(`${this.url}`, changes);
  }
}
