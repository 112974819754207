import { catchError } from 'rxjs/operators';
import { of, OperatorFunction } from 'rxjs';
import { log } from '../../testing/DevUtils/logging';

export class HttpUtils {
  //TODO handle where this is used...
  public static catchAndPrintError(): OperatorFunction<any | undefined, unknown> {
    return catchError((err) => {
      log.call(this, 'http error:' + err.status);
      return of(null);
    }) as OperatorFunction<any | undefined, unknown>;
  }
}
