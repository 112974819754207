<div *ngFor="let group of formArray.controls; index as i" [formGroup]="group" fxLayoutGap="16px">
  <mat-form-field appearance="outline" fxFlex="grow">
    <input formControlName="start" matInput type="datetime-local" />
    <mat-label>Von</mat-label>
    <mat-error>Start muss vor Ende liegen</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" fxFlex="grow">
    <input formControlName="end" matInput type="datetime-local" />
    <mat-label>Bis</mat-label>
    <mat-error>Ende muss nach Start liegen</mat-error>
  </mat-form-field>
  <button (click)="removeRange(i)" *ngIf="formArray.length > 1" class="p-t-1" mat-icon-button>
    <mat-icon>delete</mat-icon>
  </button>
</div>
<button (click)="addRange()" class="mat-raised-button" type="button">+ Weiterer Zeitraum</button>
