import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityCrudService } from '@data/entity-crud.service';
import { Participant } from '@data/participant/participant.model';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService extends EntityCrudService<null, Participant> {
  constructor(http: HttpClient) {
    super('participants', http);
  }
}
